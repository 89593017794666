<template>
  <div
    v-if="currentMode"
    class="align-items-start position-absolute configuration"
    :style="'width: ' + width + 'px'"
  >
    <div :key="componentKey" class="accordion w-100" role="tablist">
      <VueResizable
        :min-width="minWidth"
        :max-width="maxWidth"
        :active="['l']"
        class="h-100"
        :width="width"
        style="left: 0; right: 0"
        @resize:move="onResizeMove"
        @resize:end="onResizeMove"
      >
        <div class="card card-custom border-0 w-100">
          <div
            class="card-header justify-space-between align-items-center bg-white border-bottom"
            style="cursor: default"
          >
            <div class="card-title text-dark">
              <div>
                {{
                  node
                    ? $t("workflowDesigner.configureElement", {
                        label: node.attrs.label
                      })
                    : $t("workflowDesigner.selectElement")
                }}
              </div>
            </div>
            <div class="card-toolbar">
              <i
                v-if="node && currentWorkflowElement.type !== 'connector'"
                class="p-2 icon-xl fal fa-sync cursor-pointer text-hover-primary"
                @click="reloadElement(node)"
              />
              <div
                v-b-toggle.accordion-configuration
                class="p-2 pr-0 cursor-pointer mr-4"
              >
                <i
                  class="icon-xl fal text-hover-primary"
                  :class="[showPanel ? 'fa-chevrons-up' : 'fa-chevrons-down']"
                />
              </div>
            </div>
          </div>
          <b-collapse
            id="accordion-configuration"
            v-model="showPanel"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <div class="card-body d-flex">
              <div class="bg-white pt-3 pr-3 mr-3 border-right">
                <i
                  v-for="(mode, i) in modesByNode"
                  :key="i"
                  v-b-popover.hover.left="mode.label"
                  class="icon-xl mb-3 d-block"
                  :class="[
                    mode === currentMode ? 'text-primary' : '',
                    mode.icon,
                    mode.active
                      ? 'text-hover-primary cursor-pointer'
                      : 'disable-icon',
                    mode.invalid ? 'invalid' : ''
                  ]"
                  @click.prevent="mode.active ? (currentMode = mode) : ''"
                />
              </div>
              <div
                :class="
                  currentMode.name === 'output' ? 'configuration-element' : ''
                "
                style="width: calc(100% - 50px)"
              >
                <div
                  v-if="
                    currentMode.name !== 'planning' &&
                    currentMode.name !== 'documentation'
                  "
                >
                  <div class="text-h6 pt-3 mb-3 px-3">
                    {{ currentMode.label }}
                  </div>
                </div>
                <div v-else-if="currentMode.name !== 'documentation'">
                  <div class="row">
                    <b-col>
                      <div class="text-h6 pt-3 px-3">
                        {{ currentMode.label }}
                      </div>
                    </b-col>
                    <div v-if="dummyDataExist">
                      <b-col>
                        <b-button variant="primary" @click="loadDummyData">
                          {{ $t("workflowElements.loadDummyData") }}
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </div>
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="m-0 p-0"
                >
                  <component
                    :is="currentMode.component"
                    :node="node"
                    :process="process"
                    :config-values="configValues"
                    :output-values="outputValues"
                    :debug-values="debugValues"
                    :parameters="parameters"
                    :element-documentation="documentation"
                    class="flex-grow-1 px-3"
                    @change="onChange"
                  />
                </perfect-scrollbar>
              </div>
            </div>
          </b-collapse>
        </div>
      </VueResizable>
    </div>
  </div>
</template>

<script>
import Planning from "@/components/Workflows/Designer/Canvas/Configuration/Planning.vue";
import Configuration from "@/components/Workflows/Designer/Canvas/Configuration/Configuration.vue";
import Authentication from "@/components/Workflows/Designer/Canvas/Configuration/Authentication.vue";
import Input from "@/components/Workflows/Designer/Canvas/Configuration/Input.vue";
import Output from "@/components/Workflows/Designer/Canvas/Configuration/Output.vue";
import Error from "@/components/Workflows/Designer/Canvas/Configuration/Error.vue";
import Documentation from "@/components/Workflows/Designer/Canvas/Configuration/Documentation.vue";

import { bus } from "@/main";
import ConfigValues from "@/components/Settings/Config/config";
import FlowElements from "@/components/Workflows/Designer/flowElements";
import { checkTime } from "@/components/Workflows/Designer/Canvas/Components/editorHelpers";
import { mapActions, mapGetters } from "vuex";
import {
  SET_CUSTOM_VARIABLES,
  SET_VARIABLES
} from "@/core/services/store/variables_v1.module";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useStore } from "@/core/services/store";

export default {
  // components: { Planning, Authentication },
  props: ["process", "outputValues", "allWorkflowElements"],
  data() {
    return {
      node: undefined,
      currentMode: undefined,
      dummyDataExist: undefined,
      modes: [
        {
          name: "planning",
          label: this.$t("workflowDesigner.option.planning"),
          component: Planning,
          icon: "fal fa-font"
        },
        {
          name: "authentication",
          label: this.$t("workflowDesigner.option.authentication"),
          component: Authentication,
          icon: "fal fa-lock"
        },
        {
          name: "configuration",
          label: this.$t("workflowDesigner.option.configuration"),
          component: Configuration,
          icon: "fal fa-gear"
        },
        {
          name: "input",
          label: this.$t("workflowDesigner.option.input"),
          component: Input,
          icon: "fal fa-arrow-right-to-arc"
        },
        {
          name: "output",
          label: this.$t("workflowDesigner.option.output"),
          component: Output,
          icon: "fal fa-arrow-right-from-arc"
        },
        {
          name: "error",
          label: this.$t("workflowDesigner.option.error"),
          component: Error,
          icon: "fal fa-exclamation-triangle"
        },
        {
          name: "documentation",
          label: this.$t("workflowDesigner.option.documentation"),
          component: Documentation,
          icon: "fal fa-circle-question"
        }
      ],
      showPanel: true,
      configValues: [],
      parameters: [],
      debugValues: [],
      maxWidth: (window.innerWidth - 230) / 2,
      minWidth: 350,
      width: (window.innerWidth - 230) / 3,
      componentKey: 0
    };
  },
  computed: {
    ...mapGetters("variables", ["customVariablesSet"]),
    modesByNode: function () {
      let modes = [];
      this.modes.forEach(mode => {
        modes.push(mode);
        modes[modes.length - 1].active =
          !!this.node?.attrs.data[mode.name]?.length ||
          mode.name === "planning" ||
          (mode.name === "configuration" && this.node?.hasName("branch")) ||
          (mode.name === "documentation" && this.documentation);
        modes[modes.length - 1].invalid =
          this.node?.attrs.data[mode.name] &&
          this.node?.attrs.data[mode.name].invalid;
      });
      return modes;
    },
    currentWorkflowElement() {
      if (!this.node) return;
      return this.allWorkflowElements.find(
        e => e.name === this.node.attrs.data.flow_element_name
      );
    },
    documentation() {
      if (!this.node) {
        return false;
      }
      let name = this.node.attrs.data.flow_element_name;
      return this.allWorkflowElements.find(e => e.name === name)?.documentation;
    }
  },
  watch: {
    node() {
      this.node ? this.hasDummyData() : (this.dummyDataExist = false);
    }
  },
  mounted() {
    this.getConfigValues();
    this.getParameters();
    this.currentMode = this.modes[0];
    this.subscribeBusEvents();
  },
  destroyed() {
    this.unsubscribeBusEvents();
  },
  methods: {
    ...mapActions("variables", [SET_CUSTOM_VARIABLES]),
    onChange() {
      this.$emit("change");
    },
    subscribeBusEvents() {
      bus.$on("activeNodeChanged", this.setNode);
      bus.$on("update-config-values", this.getConfigValues);
      bus.$on("fireAction", this.fireAction);
      bus.$on("change-debug-values", this.onChangeDebugValues);
      bus.$on("change-mode", this.changeMode);
      bus.$on("update-custom-variable-value", this.updateCustomVariableValue);

      window.addEventListener("resize", this.onResize);
    },
    unsubscribeBusEvents() {
      bus.$off("activeNodeChanged", this.setNode);
      bus.$off("update-config-values", this.getConfigValues);
      bus.$off("fireAction", this.fireAction);
      bus.$off("change-debug-values", this.onChangeDebugValues);
      bus.$off("change-mode", this.changeMode);
      bus.$off("update-custom-variable-value", this.updateCustomVariableValue);

      window.removeEventListener("resize", this.onResize);
    },
    onResize() {
      this.maxWidth = (window.innerWidth - 230) / 2;
    },
    setNode(node) {
      this.node = node;
      this.currentMode = this.modes[0];
    },
    hasDummyData() {
      const dummyData = this.allWorkflowElements.find(
        e => e.name === this.node.attrs.data.flow_element_name
      );

      this.dummyDataExist = dummyData.dummyData.length !== 0;
    },
    loadDummyData() {
      const dummyData = this.allWorkflowElements.find(
        e => e.name === this.node.attrs.data.flow_element_name
      );
      for (const areaName of Object.keys(dummyData.dummyData)) {
        const area = dummyData.dummyData[areaName];
        Object.keys(area).forEach(fieldName => {
          let value = area[fieldName];
          if (value["fields"]) {
            value = value.fields;
          }
          if (this.node.attrs.data[areaName] === undefined) {
            return;
          }

          let field = this.node.attrs.data[areaName].find(
            field => field.name === fieldName
          );

          field ? (field.value = value) : null;
        });
      }

      this.$toast.fire({
        icon: "success",
        title: this.$t("workflowElements.setDummyData"),
        showCloseButton: true
      });
    },
    getConfigValues() {
      ConfigValues.getAll({ size: 99 })
        .then(response => {
          this.configValues = response.data;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getParameters() {
      if (!this.process?.parameters) {
        return;
      }

      const variableSet = {
        name: "parameters",
        prefix: "parameter",
        variables: this.process?.parameters
      };

      const store = useStore();
      store.dispatch("variables/" + SET_VARIABLES, variableSet);
      this.parameters = this.process?.parameters;
    },
    fireAction(field, cancel = false, silent = false) {
      if (cancel) {
        if (!silent) {
          bus.$emit("change-loading-state", false);
        }

        return;
      }

      // check required fields
      if (!this.checkActionRequiredFields(field)) {
        bus.$emit("fireActionFinished");
        return;
      }

      if (!silent) {
        bus.$emit("change-loading-state", true);
      }

      //Remove special characters from input parameters
      let inputData = this.setData(this.node.attrs.data.input);
      Object.keys(inputData).forEach(inputName => {
        if (!inputData[inputName] || typeof inputData[inputName] !== "string") {
          return;
        }
        inputData[inputName] = inputData[inputName].replace(/[|\\"']/g, "");
      });

      const data = {
        action: field.name,
        process_id: this.process.id,
        parameters: {
          hash: this.node.attrs.data.hash,
          authentication: this.setData(this.node.attrs.data.authentication),
          configuration: this.setData(this.node.attrs.data.configuration),
          input: inputData,
          output: this.setData(this.node.attrs.data.output),
          error: this.setData(this.node.attrs.data.error)
        }
      };
      const header = {};

      let getParam = this.node.attrs.data.flow_element_name;

      FlowElements.action(getParam, data, header)
        .then(response => {
          if (response?.success === false || response?.error) {
            bus.$emit("change-loading-state", false);
            this.$toast.fire({
              icon: "error",
              title: this.$t(
                (field.label?.[this.$store.getters.language] ?? field.label) +
                  " Error"
              ),
              text: response.error
            });
            return;
          }
          this.checkOutputValues(response.data);
          this.checkFields(response.data);
          this.checkConfig(response);
          this.checkGoto(field);

          if (silent) {
            return;
          }
          bus.$emit("change-loading-state", false);

          this.$toast.fire({
            icon: "success",
            title: this.$t(
              (field.label?.[this.$store.getters.language] ?? field.label) +
                " Success"
            )
          });
        })
        .catch(() => {
          bus.$emit("change-loading-state", false);
        })
        .finally(() => {
          bus.$emit("fireActionFinished");
        });
    },
    checkActionRequiredFields(actionField) {
      const action = this.getActionByName(actionField.name);
      if (!action || action.requiredFields === undefined) {
        return true;
      }
      let missingFields = [];
      for (const fieldFullName of action.requiredFields) {
        const areaName = fieldFullName.split(".")[0];
        const fieldName = fieldFullName.split(".")[1];

        const field = this.node.attrs.data[areaName].find(
          f => f.name === fieldName
        );
        if (!field) {
          continue;
        }

        if (
          field.value === undefined ||
          field.value === null ||
          field.value === "" ||
          (field.type === "json" && field.value.length === 0) ||
          (field.type === "time" && !checkTime(field.value))
        ) {
          missingFields.push(this.$t(field.label));
        }
      }

      if (missingFields.length > 0) {
        this.$toast.fire({
          icon: "error",
          title: this.$t(actionField.label + "Error"),
          html:
            this.$t("workflowElements.requiredFieldsError") +
            missingFields.join(", ")
        });
      }

      return missingFields.length === 0;
    },
    updateCustomVariableValue(data) {
      const { id, field } = data;
      let variables = [];

      // if the variables belong to the error handling we want to add "data" and "message" as nested variables
      if (data.prefix === "error") {
        variables = [
          {
            text: field.value,
            value: field.data ?? {},
            id: id,
            nested: ["data", "message"]
          }
        ];
      } else if (typeof field.value === "object") {
        variables = field.value
          ?.filter(val => !!val.key)
          .map(val => ({
            text: val.key,
            value: val.value,
            id: id
          }));
      } else {
        variables = [
          {
            text: field.value,
            value: field.data ?? {},
            id: id
          }
        ];
      }
      const payload = {
        name: data.prefix + "Values",
        variables: variables,
        prefix: data.prefix,
        showValue: true,
        merge: true
      };
      this[SET_CUSTOM_VARIABLES](payload);
    },
    checkOutputValues(data) {
      for (const output in data) {
        if (data[output].success !== undefined && !data[output].success) {
          continue;
        }

        const element = this.node.attrs.data.output.find(
          el => el.name === output
        );
        if (!element) {
          continue;
        }
        this.$set(element, "data", data[output]);
        let variables = [
          {
            text: element.value,
            value: data[output],
            id: this.node.attrs.data.hash
          }
        ];
        const payload = {
          name: "outputValues",
          variables: variables,
          prefix: "output",
          showValue: true,
          merge: true
        };
        this[SET_CUSTOM_VARIABLES](payload);
      }
    },
    checkFields(data) {
      if (data.fields === undefined) {
        return;
      }
      data.fields.forEach(f => {
        const fieldNamePats = f.name.split(".");
        const fieldArea = fieldNamePats.length > 0 ? fieldNamePats[0] : "";
        const fieldName = fieldNamePats.length > 1 ? fieldNamePats[1] : "";

        let field = this.node.attrs.data[fieldArea].find(
          f => f.name === fieldName
        );
        if (!field) return;

        this.$set(field, "options", []);
        f.options.forEach(o => {
          let value =
            typeof o === "string"
              ? {
                  value: o,
                  label: o
                }
              : o;

          if (f.name === "configuration.functions") {
            value.value = o.name;
            value.label = o.name;
            value.types = o.types;
          }
          field.options.push(value);
        });

        if (field.options.length > 0) {
          const newVal =
            field.type === "multiSelect"
              ? [field.options[0].value]
              : field.options[0].value;
          this.$set(field, "value", newVal);

          if (field.onChange !== undefined) {
            this.fireAction(
              {
                name: field.onChange,
                label: field.onChange
              },
              false,
              true
            );
          }
        }
      });
    },
    checkConfig(data) {
      if (data.config === undefined || data.config === null) {
        return;
      }
      Object.keys(data.config).forEach(areaName => {
        const area = data.config[areaName];
        if (
          typeof area !== "object" ||
          this.node.attrs.data[areaName] === undefined
        ) {
          return;
        }
        this.node.attrs.data[areaName] = [];
        Object.keys(area).forEach(valKey => {
          const val = area[valKey];
          this.node.attrs.data[areaName].push(val);
        });
      });
      this.$nextTick().then(() => {
        this.componentKey += 1;
      });
    },
    checkGoto(field) {
      if (!this.currentWorkflowElement) return;
      const action = this.getActionByName(field.name);
      if (action && action.goto) {
        const mode = this.modes.find(mode => mode.name === action.goto);
        if (mode) {
          this.currentMode = mode;
        }
      }
    },
    getActionByName(name) {
      return this.currentWorkflowElement.config.actions.find(
        action => action.name === name
      );
    },
    setData(input) {
      if (!input) {
        return {};
      }
      let data = {};
      input.forEach(val => {
        data[val.name] = val.value;
      });
      return data;
    },
    onChangeDebugValues(debugValues) {
      this.debugValues = debugValues;
    },
    changeMode(newMode) {
      this.currentMode = this.modes[newMode];
    },
    reloadElement(node) {
      const flow_element_name = node.attrs.data.flow_element_name;
      const nodeConfiguration = JSON.parse(
        JSON.stringify(node.attrs.data.configuration)
      );
      const nodeInput = JSON.parse(JSON.stringify(node.attrs.data.input));

      addEventToLoadingQueue({ key: "reloadElement" });

      //Update single element (selected node)
      FlowElements.get(flow_element_name)
        .then(response => {
          const config = response.data.config;
          nodeConfiguration.forEach((entry, index) => {
            if (entry.type === "select") {
              const freshElementFound = config.configuration.find(
                x => x.name === entry.name
              );
              node.attrs.data.configuration[index].options =
                freshElementFound.options;
            }
          });
          nodeInput.forEach((entry, index) => {
            if (entry.type === "select") {
              const freshElementFound = config.input.find(
                x => x.name === entry.name
              );
              node.attrs.data.input[index].options = freshElementFound.options;
            }
          });
          this.$toast.fire({
            icon: "info",
            title: this.$t("workflowDesigner.configurationUpdated")
          });
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue({ key: "reloadElement" });
        });
    },
    onResizeMove(payload) {
      this.width = payload.width;
    }
  }
};
</script>

<style scoped lang="scss">
.configuration {
  top: 65px;
  right: 0;
}
// classname of the perfect-scrollbar container https://github.com/mercs600/vue2-perfect-scrollbar
.ps {
  // set static height for all workflow element configuration tabs
  height: 40vh;
}
</style>

<style lang="scss">
#accordion-configuration {
  i {
    &.disable-icon {
      opacity: 0.5;
    }
  }
}

.invalid::after {
  content: "!";
  color: red;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 18px;
}

.configuration-element {
  max-height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.accordion-configuration {
  &-body {
    max-height: 50vh;
    overflow-y: auto;
  }

  &-navigation {
    position: sticky;
    top: 0;
  }
}
</style>
