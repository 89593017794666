<template>
  <ul
    class="context-menu nav flex-column position-absolute bg-white pl-0 border shadow-xs"
  >
    <template v-for="item in menuItems">
      <li
        v-if="node && node.attrs.name === (item.elementName ?? item.key)"
        :key="item.key"
        class="nav-item border-bottom"
      >
        <a
          class="nav-link"
          :class="!getItemId(item) && !item.directRoute ? 'disabled' : ''"
          href="#"
          @click.prevent="onOpenItem(item)"
        >
          {{ item.label }}
        </a>
      </li>
    </template>

    <li v-if="node && node.attrs.name === 'php'" class="nav-item border-bottom">
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('open-php-editor', node)"
      >
        {{ $t("workflowDesigner.openPhpEditor") }}
      </a>
    </li>
    <li v-if="allowEndPoint" class="nav-item border-bottom">
      <a class="nav-link" href="#" @click.prevent="$emit('end-point', node)">
        {{
          $t(
            !!node.attrs.data.is_endpoint
              ? "workflowDesigner.removeEndPoint"
              : "workflowDesigner.setEndPoint"
          )
        }}
      </a>
    </li>
    <li v-if="node && node.attrs.data.flow_element_exists" class="nav-item">
      <a class="nav-link" href="#" @click.prevent="$emit('copy', node)">
        {{ $t("general.copyToClipboard") }}
      </a>
    </li>
    <li
      v-if="node && node.attrs.data.flow_element_exists && copyChildNode"
      class="nav-item"
    >
      <a
        class="nav-link"
        href="#"
        @click.prevent="$emit('copyWithChildren', node)"
      >
        {{ $t("general.copyGroupToClipboard") }}
      </a>
    </li>
    <li
      v-if="node && isNotTrigger && node.attrs.data.flow_element_exists"
      class="nav-item"
    >
      <a class="nav-link" href="#" @click.prevent="$emit('deactivate', node)">
        <div v-if="isActive">
          {{ $t("general.deactivate") }}
        </div>
        <div v-else>
          {{ $t("general.activate") }}
        </div>
      </a>
    </li>
    <li v-if="allowDelete" class="nav-item">
      <a class="nav-link" href="#" @click.prevent="$emit('delete', node)">
        {{ $t("general.delete") }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  props: {
    node: {
      type: Object,
      default: () => {}
    },
    specialElements: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      menuItems: [
        {
          key: "dataStructure",
          elementName: "validation",
          label: this.$t("workflowDesigner.openDataStructure"),
          route: "projectDataStructuresEditor"
        },
        {
          key: "dataStructure",
          elementName: "dataStore",
          label: this.$t("workflowDesigner.openDataStructure"),
          route: "projectDataStructuresEditor"
        },
        {
          key: "mapping",
          label: this.$t("workflowDesigner.openMapping"),
          route: "projectMappingsMappingMapper"
        },
        {
          key: "dataSet",
          elementName: "dataSets",
          label: this.$t("workflowDesigner.openDataSet"),
          route: "projectDataSetsEditor"
        },
        {
          key: "textTemplate",
          label: this.$t("workflowDesigner.openTextTemplate"),
          route: "projectTextTemplatesEditor"
        },
        {
          key: "assignment",
          elementName: "assignmentTable",
          group: "input",
          label: this.$t("workflowDesigner.openAssignmentTable"),
          route: "projectMappingsAssignmentsEditor"
        },
        {
          key: "process",
          elementName: "internalTrigger",
          label: this.$t("workflowDesigner.openWorkflow"),
          route: "projectWorkflowsEditorModePlanning"
        },
        {
          elementName: "fileAccess",
          label: this.$t("workflowDesigner.openFileExplorer"),
          route: "projectFileExplorer",
          directRoute: true
        }
      ]
    };
  },
  computed: {
    allowEndPoint: function () {
      return this.node && this.isNotTrigger && !this.node.attrs.data.next_hash;
    },
    allowDelete: function () {
      return !!this.node;
    },
    isNotTrigger: function () {
      return this.node.attrs.type !== "trigger";
    },
    isActive: function () {
      return this.node.attrs.data.active;
    },
    copyChildNode: function () {
      return (
        this.specialElements.includes(this.node.attrs.data.flow_element_name) ||
        (this.node.attrs.data?.error[0]?.name === "explicitErrorHandling" &&
          this.node.attrs.data.error[0].value)
      );
    }
  },
  mounted() {},
  methods: {
    getItemId(item) {
      let field = this.node.attrs.data[item.group ?? "configuration"].find(
        f => f.name === item.key
      );
      return field?.value;
    },
    onOpenItem(item) {
      if (item.directRoute) {
        const route = this.$router.resolve({ name: item.route });
        window.open(route.href, "_blank");
        return;
      }
      const id = this.getItemId(item);
      if (id === null) {
        return;
      }
      const route = this.$router.resolve({
        name: item.route,
        params: { id: id }
      });
      window.open(route.href, "_blank");
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.context-menu {
  border-radius: 12px;
  overflow: hidden;
}
a {
  color: $color-connect-text;

  &:hover {
    background: $color-connect-primary-medium;
  }
}
</style>
