<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { typeTranslation } from "@/components/Workflows/Designer/Canvas/Configuration/functions";
import _ from "lodash";
import { bus } from "@/main";
import { generateHash } from "@/components/Tools/helperFunctions";
export default {
  components: { FormHelper },
  props: ["node", "configValues", "outputValues", "debugValues", "errorValues"],
  data() {
    return {
      areas: ["authentication", "configuration", "input", "output", "error"],
      formConfig: {
        snippetPrefix: this.snippetPrefix(),
        labelStacked: true
      },
      debouncedUpdate: _.debounce(this.updateErrorMessageValue, 1000)
    };
  },
  computed: {
    values: {
      get() {
        let values = {};
        this.areas.forEach(area => (values[area] = {}));
        Object.keys(this.node.attrs.data).forEach(area => {
          if (!this.areas.includes(area)) {
            return;
          }
          this.node.attrs.data[area].forEach(field => {
            let value = field.value;

            if (field.name === "exceptionGroups" && value) {
              value = value.filter(val => !val.default);
            }

            values[area][field.name] = value;
          });
        });
        return _.cloneDeep(values);
      },
      set(data) {
        let config = _.cloneDeep(this.node.attrs.data.error),
          values = _.cloneDeep(data);
        Object.keys(values.error).forEach(key => {
          let field = config.find(f => f.name === key);
          let value = values.error[key];
          if (field.name === "exceptionGroups") {
            let exceptionGroupsField = config.find(
              f => f.name === "exceptionGroups"
            );
            let defaultValues = exceptionGroupsField.value.filter(
              val => val.default
            );
            value.unshift(defaultValues[0]);
            value.push(defaultValues[1]);
          }
          field.value = value;
        });
        this.node.attrs.data.error = config;
      }
    },
    form: function () {
      let form = this.node?.attrs.data.error ?? [];
      return typeTranslation(form, "error");
    }
  },
  mounted() {
    const payload = {
      node: this.node
    };

    bus.$emit("open-error-configuration", payload);
  },
  methods: {
    snippetPrefix() {
      return (
        "workflowElements." +
        this.$store.getters.getElementByName(
          this.node.attrs.data.flow_element_name
        ).name
      );
    },
    onChange(payload) {
      this.$emit("change");
      this.$nextTick().then(() => {
        if (payload.name === "error.exceptionGroups") {
          if (payload.valuePath !== "" && !isNaN(payload.valuePath)) {
            if (payload.remove) {
              this.removeErrorBranches(payload.value.id);
            } else if (payload.move) {
              this.moveBranch(
                payload.value.id,
                payload.valuePath,
                payload.newIndex
              );
            } else {
              this.addErrorBranches(payload.valuePath);
            }
          } else if (payload.valuePath.includes("label")) {
            let emitPayload = {
              node: this.node,
              dataKey: "error",
              elementName: "exceptionGroups"
            };
            bus.$emit("refresh-branch-label", emitPayload);
          }
        } else if (payload.name === "error.explicitErrorHandling") {
          payload.value
            ? this.activateExplicitErrorHandling()
            : this.deactivateExplicitErrorHandling();
        } else {
          this.debouncedUpdate(payload);
        }
      });
    },
    addErrorBranches(index) {
      index++;
      let id = generateHash();
      let condition = this.node.attrs.data.error.find(
        el => el.name === "exceptionGroups"
      ).value[index];
      this.$set(condition, "id", id);
      let payload = {
        node: this.node,
        id: id
      };
      bus.$emit("add-exception-branch", payload);
    },
    moveBranch(id, oldIndex, newIndex) {
      const payload = {
        node: this.node,
        id: id,
        oldIndex: oldIndex,
        newIndex: newIndex,
        isExplicitErrorHandlingBranch: true
      };
      bus.$emit("move-condition", payload);
    },
    removeErrorBranches(id) {
      const payload = {
        node: this.node,
        id: id
      };
      bus.$emit("remove-exception-branch", payload);
    },
    activateExplicitErrorHandling() {
      let payload = {
        node: this.node
      };
      bus.$emit("activate-explicit-error-handling", payload);
    },
    deactivateExplicitErrorHandling() {
      let payload = {
        node: this.node
      };
      bus.$emit("deactivate-explicit-error-handling", payload);
    },
    updateErrorMessageValue(payload) {
      let field = this.node.attrs.data.error.find(
        f => "error." + f.name === payload.name
      );
      // Add index to id for handling multiple output value fields
      let index = this.node.attrs.data.output.indexOf(field);
      if (!field || field.type !== "text") {
        return;
      }
      bus.$emit("update-custom-variable-value", {
        id: this.node.attrs.data.hash + "-" + index,
        field: field,
        prefix: "error"
      });
    }
  }
};
</script>
