import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class FlowComments extends BaseService {
  getAll(
    params,
    filter = [],
    fields = ["comment", "extra_data", "id", "workflow_id"]
  ) {
    let filterParams = this.getParams(filter, params, fields);
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "flow_comments?" + options + filterParams);
  }

  store(data) {
    return ApiService.post(url + "/flow_comments", data);
  }

  update(id, data) {
    return ApiService.put(url + "/flow_comments/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/flow_comments/" + id);
  }
}
export default new FlowComments();
