export function circleScene(context, shape) {
  let width = shape.width();
  let height = shape.height();
  context.beginPath();
  context.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2);
  context.lineWidth = 1;
  context.fillStyle = "#fff";
  context.fill();
  context.fillStrokeShape(shape);
}

export function dropzoneScene(context, shape) {
  let width = shape.width();
  let height = shape.height();

  context.strokeStyle = shape.stroke();

  context.beginPath();
  context.moveTo(0, 0);
  context.lineTo(width, 0);
  context.lineTo(width, height);
  context.lineTo(0, height);
  context.closePath();
  context.lineWidth = 1;
  context.stroke();

  context.fillStyle = "#f6f6f6";
  context.fill();

  context.beginPath();
  context.moveTo(width / 2, 3);
  context.lineTo(width / 2, height - 3);
  context.lineWidth = 1;
  context.stroke();

  context.beginPath();
  context.moveTo(3, height / 2);
  context.lineTo(width - 3, height / 2);
  context.lineWidth = 1;
  context.stroke();

  context.fillStrokeShape(shape);
}

export function checkFieldDepending(node, field) {
  if (!field.dependsOn) {
    return true;
  }

  let dependSuccessCount = 0;
  field.dependsOn.forEach(dependOn => {
    const dependOnArea = dependOn.name.split(".").shift();
    const dependOnField = dependOn.name.split(".").pop();
    const fieldDependsOn = Object.values(node.attrs.data[dependOnArea]).find(
      f => f.name === dependOnField
    );

    if (fieldDependsOn) {
      if ("notValues" in dependOn) {
        if (typeof fieldDependsOn.value === "object") {
          let isInNotValues = false;
          (fieldDependsOn?.value || []).forEach(v => {
            if (dependOn?.notValues?.includes(v)) {
              isInNotValues = true;
            }
          });
          if (!isInNotValues) {
            dependSuccessCount++;
          }
        } else {
          if (!dependOn?.notValues?.includes(fieldDependsOn.value)) {
            dependSuccessCount++;
          }
        }
      } else if ("values" in dependOn) {
        (dependOn?.values || []).forEach(dependsOnValue => {
          if (
            dependsOnValue === fieldDependsOn.value ||
            (typeof fieldDependsOn.value === "object" &&
              fieldDependsOn?.value?.includes(dependsOnValue))
          ) {
            dependSuccessCount++;
          }
        });
      } else {
        (dependOn?.values || []).forEach(dependsOnValue => {
          if (dependsOnValue === fieldDependsOn?.value) {
            dependSuccessCount++;
          }
        });
      }
    }
  });

  return dependSuccessCount === field.dependsOn.length;
}

export function workflowElementToData(node) {
  const data = node.attrs.data;

  return {
    version: 1,
    active: data.active,
    label: data.planning.label,
    name: node.attrs.name,
    description: data.planning.description,
    comment: data.planning.comment,
    icon: data.planning?.icon,
    hash: data.hash,
    group_hash: data.group_hash ?? "",
    next_hash: data.next_hash ? data.next_hash : null,
    next_inner_hash: data.next_inner_hash ? data.next_inner_hash : null,
    workflow_id: data.workflow_id,
    flow_element_name: data.flow_element_name ? data.flow_element_name : null,
    // Deprecated, can be removed when CONNECT-2875 is merged
    workflow_element_name: data.flow_element_name
      ? data.flow_element_name
      : null,
    x: parseInt(node.attrs.x),
    y: parseInt(node.attrs.y),
    absolute_y: parseInt(node.getAbsolutePosition().y),
    is_endpoint: data.is_endpoint,
    config: {
      authentication: setData(node, data.authentication),
      configuration: setData(node, data.configuration),
      input: setData(node, data.input),
      output: setData(node, data.output, false),
      error: setData(node, data.error)
    }
  };
}

export function setData(node, input, onlyValue = true) {
  if (!input) {
    return {};
  }
  let data = {};
  input.forEach(val => {
    if (!checkFieldDepending(node, val)) {
      return;
    }
    if (val.type === "action") {
      return;
    }

    if (val.singleEntry && val.value && val.value.length === 1) {
      data[val.name] = val.value[0];
    } else {
      data[val.name] = onlyValue ? val.value : val;
    }
  });
  return data;
}

export function checkTime(val) {
  const value = val.trim();
  switch (true) {
    case value.trim().length === 0:
      return false;
    case value.trim().startsWith("{{config."):
    case /^([0-9]+d)$/.test(value.trim()):
    case /^(([01]?[0-9]|2[0-3])h)$/.test(value.trim()):
    case /^(([0-5]?[0-9])m)$/.test(value.trim()):
    case /^([0-9]+d\s)(([01]?[0-9]|2[0-3])h)$/.test(value.trim()):
    case /^(([01]?[0-9]|2[0-3])h\s)(([0-5]?[0-9])m)$/.test(value.trim()):
    case /^([0-9]+d\s)(([0-5]?[0-9])m)$/.test(value.trim()):
    case /^([0-9]+d\s)(([01]?[0-9]|2[0-3])h\s)(([0-5]?[0-9])m)$/.test(
      value.trim()
    ):
      return true;
    default:
      return false;
  }
}
