<template>
  <div class="layout-grid">
    <Header
      :title="$t('workflowDesigner.workflowDesignerTable').toString()"
      :items="headerItems"
      @reload-workflows="reloadWorkflows"
      @create-workflow="createWorkflow"
      @add-process-group="addProcessGroup"
      @collapse-all="collapseAll"
      @collapse-none="collapseNone"
    >
      <template v-if="!isPresetProject && isDevPresetVersion" #afterItems>
        <file-upload
          ref="upload"
          v-b-popover.hover.top="$t('workflowDesigner.workflowImportHelp')"
          extensions="json,yaml,yml"
          accept="application/json,.yml,.yaml"
          class="btn btn-outline-primary btn-hover ml-2"
          @input-file="inputFile"
        >
          <i class="fa-light fa-file-import"></i>
        </file-upload>
      </template>
    </Header>

    <div
      v-if="
        !$store.getters['loadingQueue/showLoadingBar']() &&
        $store.getters.processTableEntries.length < 1
      "
    >
      <div class="d-flex justify-content-center">
        <div class="w-50">
          <Card>
            <div
              class="text-hover-primary cursor-pointer p-6"
              @click="$router.push({ name: 'projectWorkflowsCreate' })"
            >
              <div class="row">
                <div class="col-4">
                  <img
                    alt="Workflow"
                    class="w-100"
                    src="media/img/workflow.png"
                  />
                </div>
                <div
                  class="col-8 text-center d-flex align-center justify-center"
                >
                  <div>
                    <i
                      class="fal fa-plus-circle mb-6"
                      style="font-size: 3.5rem"
                    />
                    <h3>
                      {{ $t("workflowDesigner.createNewWorkflow") }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
    <div
      v-show="
        $store.getters.processTableEntries.length > 0 ||
        $store.getters['loadingQueue/showLoadingBar']()
      "
      class="workflow-listing"
    >
      <ProjectWorkflow ref="projectWorkflow" />
    </div>
  </div>
</template>

<script>
import ProjectWorkflow from "@/components/Workflows/Designer/Components/WorkflowTable.vue";
import { mapGetters } from "vuex";
import FileUpload from "vue-upload-component";
import workflowManager from "@/components/Workflows/workflowManager";
import FlowTableEntries from "@/components/Workflows/Designer/Components/flowTableEntries";
import { ADD_TABLE_ENTRY } from "@/core/services/store/process.module";
import Swal from "sweetalert2";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Card from "@/components/Tools/Card/Card.vue";
import { addCollapseProcessTableEntry } from "@/components/Workflows/Designer/Components/ProcessTableEntry/useProcessTableEntries";

export default {
  name: "designerTable",
  components: {
    Card,
    Header,
    ProjectWorkflow,
    FileUpload
  },
  props: [],
  data() {
    return {
      files: [],
      componentKey: 0
    };
  },
  computed: {
    ...mapGetters(["selectedProject", "isPresetProject", "isDevPresetVersion"]),
    headerItems: function () {
      let headerItems = [
        {
          type: "button",
          icon: "fal fa-folder",
          class: "btn-secondary",
          emit: "collapse-all",
          tooltip: this.$t("workflowDesigner.collapseNone")
        },
        {
          type: "button",
          icon: "fal fa-folder-open",
          class: "btn-secondary",
          emit: "collapse-none",
          tooltip: this.$t("workflowDesigner.collapseAll")
        },
        {
          type: "button",
          icon: "fal fa-sync-alt",
          class: "btn-secondary",
          emit: "reload-workflows"
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerItems;
      }
      headerItems.push(
        {
          type: "button",
          title: this.$t("workflowDesigner.workflowCreate"),
          icon: "fa-light fa-plus",
          emit: "create-workflow"
        },
        {
          type: "button",
          icon: "fa-light fa-layer-group",
          emit: "add-process-group",
          class: "btn-outline-primary btn-hover",
          tooltip: this.$t("workflowDesigner.workflowGroupCreate")
        }
      );
      return headerItems;
    }
  },
  methods: {
    createWorkflow() {
      this.$router.push({ name: "projectWorkflowsCreate" });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        addEventToLoadingQueue({ key: "loadingFile" });

        let importData = new FormData();
        importData.append("file", newFile.file);

        if (this.selectedProject.id) {
          importData.append("project_id", this.selectedProject.id);
        }

        workflowManager
          .import(importData)
          .then(() => {
            this.reloadWorkflows();
            removeEventFromLoadingQueue({
              key: "loadingFile",
              type: "success",
              prefix: "workflowDesigner",
              name: "workflowImportSuccess"
            });
          })
          .catch(error => {
            this.$error(error);
          });
      }
    },
    addProcessGroup() {
      Swal.fire({
        title: this.$t("workflowDesigner.workflowGroupCreate"),
        icon: "info",
        input: "text",
        confirmButtonColor: "#5b64ee",
        confirmButtonText: this.$t("general.save")
      }).then(result => {
        if (result.isConfirmed) {
          const label = result.value;
          addEventToLoadingQueue({ key: "addGroup" });
          FlowTableEntries.create({
            project_id: this.selectedProject.id,
            label: label
          })
            .then(response => {
              removeEventFromLoadingQueue({ key: "addGroup" });
              if (!response?.data?.data) return;

              this.$store.dispatch(ADD_TABLE_ENTRY, response.data.data);
              addCollapseProcessTableEntry(response.data.data.id);
            })
            .catch(error => {
              this.$error(error);
            })
            .finally(() => {
              this.reloadWorkflows();
            });
        }
      });
    },
    reloadWorkflows() {
      this.$refs.projectWorkflow.loadWorkflows();
    },
    collapseAll() {
      this.$refs.projectWorkflow.collapseAll();
    },
    collapseNone() {
      this.$refs.projectWorkflow.collapseNone();
    }
  }
};
</script>

<style lang="scss" scoped>
.layout-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
  // Only temporary
  height: calc(100vh - 32px);

  .workflow-listing {
    overflow-y: scroll;
    border-radius: 12px;
  }
}
.file-uploads.file-uploads-html5 label {
  cursor: pointer;
}

.btn-outline-primary {
  &:hover {
    i {
      color: #fff !important;
    }
  }
}
</style>
