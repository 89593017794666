<template>
  <div>
    <Table v-if="$route.name === 'projectWorkflows'" />
    <Create v-else-if="$route.name === 'projectWorkflowsCreate'" />
    <Designer v-else-if="$route.name.includes('projectWorkflowsEditor')" />
    <JobsTable v-else-if="$route.name.includes('projectWorkflowsReporting')" />
    <Details v-else-if="$route.name.includes('projectWorkflowsDetail')" />
    <JobDetails
      v-else-if="$route.name.includes('projectWorkflowsJobDetails')"
    />
  </div>
</template>

<script>
import Designer from "@/components/Workflows/Designer/Designer.vue";
import Table from "@/components/Workflows/Designer/Table.vue";
import Create from "@/components/Workflows/Designer/CreateWizard.vue";
import JobsTable from "@/components/Workflows/Reporting/Components/JobsTable.vue";
import Details from "@/components/Workflows/Detail/Index.vue";
import JobDetails from "@/components/Workflows/Reporting/Index.vue";

export default {
  name: "designerIndex",
  components: {
    Designer,
    Table,
    Create,
    JobsTable,
    Details,
    JobDetails
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.form-control {
  &.v-select {
    .v-input__slot {
      padding-top: 12px;
    }
  }
}
</style>
