<template>
  <div>
    <TableHelper
      ref="table"
      :title="$t('reporting.logs')"
      :items="logs"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      actions-align-class="text-left"
      :enable-filter="true"
      param-prefix="logs-table"
      :no-route-params="true"
      :filterable="filterable"
      :filterable-field-blacklist="filterableFieldBlacklist"
      :exportable="true"
      loading-key="loadLogs"
      :progress-bar-key="isModal ? 'loadingSidebar' : null"
      group-key="loadLogs"
      @reload-data="loadLogs"
      @toggle-details="loadLog($event, 'detail')"
      @open-job-reporting="openJobReporting"
      @export-csv="exportCsv"
    />
    <v-dialog
      ref="showDetailModal"
      v-model="showDetailModal"
      transition="dialog-bottom-transition"
      content-class="bg-white pt-5 p-4"
      width="1000"
    >
      <div>
        <h3 class="mb-5">{{ $t("reporting.details") }}</h3>
      </div>
      <FormHelper
        key="1"
        v-model="formValues"
        :form="details"
        :config="config"
      />
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/helperFunctions";
import Logs from "@/components/Workflows/Reporting/Components/processLogs";
import { mapGetters } from "vuex";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: {
    FormHelper,
    TableHelper
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    process: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      actions: [
        {
          key: "toggleDetails",
          icon: "fal fa-info-circle",
          emit: "toggle-details",
          tooltip: this.$t("reporting.showDetails")
        },
        {
          key: "openJobReporting",
          icon: "fal fa-search",
          emit: "open-job-reporting",
          tooltip: this.$t("processManager.openReporting"),
          condition: {
            field: "meta.processIterationId",
            operator: "!==",
            value: undefined
          }
        }
      ],
      config: {
        labelStacked: true
      },
      details: [
        {
          name: "data",
          type: "code",
          lang: "json",
          style: "dark",
          readOnly: true,
          disabled: true
        }
      ],
      elementInfo: [
        {
          name: "info",
          type: "code",
          lang: "json",
          style: "dark",
          readOnly: true,
          disabled: true
        }
      ],
      formValues: {
        data: null,
        info: null
      },
      showDetailModal: false,
      jobId: "",
      logs: [],
      logsData: [],
      fields: [
        {
          key: "order_index",
          label: this.$t("table.orderIndex"),
          sortable: true,
          thStyle: { width: "110px" }
        },
        {
          key: "flow_element_name",
          label: this.$t("table.workflowComponent"),
          sortable: true,
          thStyle: { width: "300px" }
        },
        {
          key: "type",
          label: this.$t("table.type"),
          type: "status",
          statusType: "log",
          thStyle: { width: "40px" }
        },
        {
          key: "message",
          label: this.$t("table.message"),
          sortable: true
        },
        {
          key: "updated_at",
          label: this.$t("table.timestamp"),
          sortable: true,
          thStyle: { width: "170px" },
          type: "datetime"
        }
      ],
      meta: {},
      // Filterable fields
      filterable: {},
      filterableFieldBlacklist: ["project_id", "flow_execution_id"]
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"])
  },
  watch: {
    "$route.params.jobId": {
      handler: function (jobId) {
        if (this.jobId && jobId !== this.jobId) {
          this.jobId = this.$route.params.jobId;
          this.loadLogs();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.jobId = this.$route.params.jobId;
    this.loadLogs();
  },
  methods: {
    formatDate,
    loadLogs(payload = {}) {
      if (this.jobId === undefined) {
        return;
      }

      let eventParams = {
        key: "loadLogs",
        progressBarKey: null,
        group: "loadLogs"
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      this.logs = [];
      let params = { ...this.requestParams(), ...payload };

      if (!params?.filter) {
        params.filter = [];
      }

      params.filter.push({
        key: "status",
        op: "equals",
        value: this.selectedStatus
      });
      params.filter.push({
        key: "flow_execution_id",
        op: "equals",
        value: this.jobId
      });

      Logs.getAll(params, true)
        .then(response => {
          this.logs = response.data.map(log => {
            let nameParts = [log.flow_element.name];
            if (
              log.flow_element.title !== null &&
              log.flow_element.title !== ""
            ) {
              nameParts.push(log.flow_element.title);
            }
            log.flow_element_name = nameParts.join(" / ");
            return log;
          });
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    copyValue(data) {
      copyToClipboard(JSON.stringify(data));
    },
    loadLog(data, type) {
      let eventParams = {
        key: "loadLog",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      Logs.get(data.item.id)
        .then(response => {
          this.logsData = response.data;
          if (type === "detail") {
            this.formValues.data = JSON.stringify(this.logsData, null, 2);
            this.showDetailModal = true;
          }
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    exportCsv(params) {
      params.filters.push({
        key: "flow_execution_id",
        op: "equals",
        value: this.jobId
      });
      Logs.exportCsv(params).catch(error => {
        this.$error(error);
      });
    },
    openJobReporting(data) {
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: {
          id: data.item.meta.processId,
          jobId: data.item.meta.processIterationId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vjs-tree__node {
  span {
    span {
      word-break: break-all;
    }
  }
}

:deep(.CodeMirror) {
  height: 100%;
}
</style>
