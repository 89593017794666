<template>
  <VueResizable
    v-if="show"
    :min-width="250"
    :max-width="maxWidth"
    :active="['r']"
    @resize:move="onElementResize"
  >
    <div
      class="card card-custom border-0 position-relative"
      :class="{ 'd-none': !show }"
    >
      <div class="card-body library px-0" style="max-width: 100%">
        <div class="form-group mb-3 px-7">
          <div class="input-group">
            <input
              v-model.trim="searchTitle"
              type="text"
              class="form-control"
              :placeholder="$t('workflowDesigner.searchElements')"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i
                  v-if="!searchTitle.length"
                  class="fal fa-magnifying-glass"
                ></i>
                <i
                  v-else
                  class="fal fa-xmark cursor-pointer"
                  @click="searchTitle = ''"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div id="accordionElements" class="accordion accordion-toggle-arrow">
          <div
            v-for="(type, i) in types"
            :key="i"
            class="card border-right-0 border-left-0"
          >
            <div class="card-header bg-white">
              <div
                v-b-toggle="'collapse' + i"
                class="card-title justify-space-between"
                :class="shouldHighlightType(type) ? 'highlight' : ''"
              >
                <div class="library-category-title">
                  <div>
                    {{
                      $t(
                        "workflowDesigner.elements" +
                          type[0].toUpperCase() +
                          type.substring(1)
                      )
                    }}
                  </div>
                </div>
                <i v-if="shouldHighlightType(type)" class="fal fa-boxes mr-6" />
                <div
                  v-if="typeof elementsPerTypeFiltered[type] === 'number'"
                  class="badge ml-1 mr-3"
                >
                  {{ elementsPerTypeFiltered[type] }}
                </div>
              </div>
            </div>

            <b-collapse
              :id="'collapse' + i"
              accordion="element-accordion"
              :class="shouldHighlightType(type) ? 'highlight' : ''"
            >
              <perfect-scrollbar>
                <div class="card-body">
                  <div v-if="!elements[type]">
                    {{ $t("workflowDesigner.noElementsOfType") }}
                  </div>
                  <div v-else class="row">
                    <div
                      v-for="(element, ii) in elements[type]"
                      :key="ii"
                      class="flex-wrap justify-content-center"
                      :class="[
                        elementColClass,
                        (searchTitle.length && !searchElement(element)) ||
                        element.name === 'workflow'
                          ? 'd-none'
                          : 'd-flex'
                      ]"
                    >
                      <Element :data="element" />
                      <div class="w-100 text-center">
                        <span>{{ element.label }}</span>
                      </div>
                    </div>
                    <span
                      v-if="elementsPerTypeFiltered[type] === 0"
                      class="px-3 mb-3"
                    >
                      {{ $t("workflowDesigner.searchElementsNotFound") }}
                    </span>
                  </div>
                </div>
              </perfect-scrollbar>
            </b-collapse>
          </div>
          <ClipboardElements
            v-if="elementsUnsorted.length"
            :search-title="searchTitle"
            :library="elementsUnsorted"
          />
        </div>
      </div>
    </div>
  </VueResizable>
</template>

<script>
import Element from "@/components/Workflows/Designer/Canvas/Components/LibraryElement.vue";
import FlowElements from "@/components/Workflows/Designer/flowElements";
import ClipboardElements from "@/components/Workflows/Designer/Canvas/Components/ClipboardElements.vue";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";

export default {
  components: {
    ClipboardElements,
    Element
  },
  props: {
    show: Boolean
  },
  data() {
    return {
      types: [],
      elements: {},
      elementsUnsorted: [],
      searchTitle: "",
      maxWidth: window.innerWidth / 2,
      elementColClass: "col-6"
    };
  },
  computed: {
    ...mapGetters({
      allElementsStore: GET_ALL_ELEMENTS
    }),
    elementsPerTypeFiltered: function () {
      let searchTitle = this.searchTitle.trim().toLowerCase();
      if (!searchTitle.length) return {};
      let elements = this.elementsUnsorted;
      let elementsPerType = {};
      elements.forEach(element => {
        if (elementsPerType[element.type] === undefined)
          elementsPerType[element.type] = 0;
        if (this.searchElement(element)) elementsPerType[element.type]++;
      });
      return elementsPerType;
    }
  },
  watch: {
    allElementsStore: function (value) {
      this.elementsUnsorted = value;
      this.sortElementsByType(value);
    }
  },
  mounted() {
    this.getElementTypes();
    if (this.allElementsStore.length > 0) {
      this.elementsUnsorted = this.allElementsStore;
      this.sortElementsByType(this.allElementsStore);
    }
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.maxWidth = window.innerWidth / 2;
    },
    onElementResize(e) {
      if (e.width >= 610) {
        this.elementColClass = "col-2";
      } else if (e.width >= 500) {
        this.elementColClass = "col-3";
      } else if (e.width >= 380) {
        this.elementColClass = "col-4";
      } else {
        this.elementColClass = "col-6";
      }
    },
    getElementTypes() {
      FlowElements.getTypes()
        .then(response => {
          this.types = response.data;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    sortElementsByType(elements) {
      this.elements = [];
      elements.forEach(element => {
        let type = element.type;
        if (!this.elements[type]) this.elements[type] = [];
        this.elements[type].push(element);
      });
      let me = this;
      this.$nextTick().then(() => {
        me.$emit("ready");
      });
    },
    searchElement(element) {
      return JSON.stringify(element)
        .toLowerCase()
        .includes(this.searchTitle.toLowerCase());
    },
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    /* eslint-disable */
    shouldHighlightType(type) {
      return false;
      //return type === "data" || type === "apiGateway";
    }
    /* eslint-enable */
  }
};
</script>

<style lang="scss">
.card-header,
.card-title {
  outline: none;
  .library-category-title {
    max-width: 80%;
    min-width: 0;
    div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .badge {
    background-color: #e4e6ef;
  }
}
.library {
  .accordion {
    .ps {
      max-height: calc(40vh);
    }
  }
}

.highlight {
  background: aliceblue;
}
</style>
