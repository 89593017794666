var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative d-flex flex-wrap justify-content-center designer-element-wrapper"},[_c('div',{staticClass:"designer-element rounded-circle border",attrs:{"data-type":_vm.data.type,"data-name":_vm.data.name,"data-clipboard":_vm.index,"draggable":"true"}},[_c('span',{style:('background-image: url(' + _vm.elementIcon + ')')})]),_c('div',{staticClass:"w-100 text-center"},[_c('span',[_vm._v(_vm._s(_vm.data.label ? _vm.data.label : _vm.original.label)+" ")]),(
        (_vm.data.children && _vm.data.config.error.explicitErrorHandling) ||
        ['branch', 'loop', 'cache'].includes(_vm.data.name)
      )?_c('span',{staticClass:"badge badge-light",staticStyle:{"font-size":"0.6rem"}},[_vm._v("+"+_vm._s(_vm.countNestedChildren(_vm.data))+" ")]):_vm._e(),(
        !Array.isArray(_vm.data.config) ||
        Object.keys(_vm.data.config).includes('configuration')
      )?_c('span',{staticClass:"ml-1"},[_c('i',{staticClass:"fal fa-circle-check text-success",staticStyle:{"font-size":"1rem"}})]):_vm._e(),_c('span',{staticClass:"cursor-pointer remove-clipboard-element"},[_c('i',{staticClass:"fal fa-trash text-hover-primary",staticStyle:{"font-size":"1rem"},on:{"click":_vm.removeFromClipboard}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }