<template>
  <draggable
    v-bind="dragOptions"
    class="dragArea pl-0 mb-0"
    :class="['level-' + level]"
    tag="ul"
    handle=".handle"
    :list="list"
    :value="value"
    :group="{ name: 'workflowTable' }"
    @input="emitter"
    @change="updateOrderIndexes"
  >
    <li
      v-for="(processTableEntry, index) in realValue"
      :id="processTableEntry.id ?? processTableEntry['id']"
      :key="index"
      :class="'li-' + processTableEntry.id ?? processTableEntry['id']"
    >
      <!------------ START: Groups ------------->
      <div
        v-if="processTableEntry.type === 'group'"
        class="d-flex flex-row pl-4"
        :class="processTableEntry.children.length === 0 ? '' : 'pb-3'"
      >
        <div class="pt-3 pr-2">
          <i class="fa-regular fa-bars justify handle cursor-move"></i>
        </div>

        <div class="p-1 pt-3">
          <button @click="toggleChildren(processTableEntry)">
            <i
              class="fal"
              :class="
                processTableEntriesConfig[processTableEntry.id]?.collapsed
                  ? 'fa-folder'
                  : 'fa-folder-open'
              "
            ></i>
            <span class="h6 pl-2">{{ processTableEntry.label }}</span>
          </button>
          <div class="groupChildren pl-2">
            <span class="text-muted"
              >{{ $t("workflowDesigner.childProcesses") }}:
            </span>
            <i class="font-italic group-description-text">{{
              getDescription(processTableEntry.children)
            }}</i>
          </div>
        </div>
        <div class="ml-auto py-3 pr-3">
          <!--          Action Buttons-->
          <button
            v-b-popover.hover.top="$t('workflowDesigner.editGroup')"
            class="btn btn-icon btn-sm mr-1 btn-custom"
            @click.left="editGroup(processTableEntry)"
          >
            <i class="fal fa-pen" />
          </button>
          <v-menu
            :attach="'.li-' + processTableEntry.id ?? processTableEntry['id']"
            left
            offset-y
          >
            <template #activator="{ on, attrs }">
              <button
                class="btn btn-icon btn-sm btn-custom"
                v-bind="attrs"
                v-on="on"
              >
                <i class="fal fa-ellipsis-v" />
              </button>
            </template>
            <ul class="navi navi-hover p-0">
              <li
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="
                    deleteGroup(processTableEntry.id, processTableEntry.label)
                  "
                >
                  <span class="navi-text">
                    {{ $t("workflowDesigner.deleteGroup") }}
                  </span>
                </a>
              </li>
            </ul>
          </v-menu>
        </div>
      </div>
      <!------------ START: Process entry ------------>
      <div
        v-else-if="processTableEntry.type === 'process'"
        v-show="isCollapsed"
        class="d-flex flex-row pl-4 process"
        :class="processTableEntry.children.length === 0 ? '' : 'pb-3'"
      >
        <div class="pt-4 pr-2">
          <i class="fa-regular fa-bars handle cursor-move"></i>
        </div>
        <div class="pt-4">
          <!------------ START: Folder icon / Collapse action ------------>
          <i
            v-if="processTableEntry.children.length"
            v-b-popover.hover.top="
              processTableEntry.children.length
                ? getStatusTooltipText(
                    processTableEntry.process?.last_execution_status
                  )
                : ''
            "
            class="fal cursor-pointer px-1"
            :class="[
              processTableEntriesConfig[processTableEntry.id]?.collapsed
                ? 'fa-folder'
                : 'fa-folder-open',
              processTableEntry.children.length
                ? getStatusColor(
                    processTableEntry.process?.last_execution_status
                  )
                : ''
            ]"
            @click="toggleChildren(processTableEntry)"
          />
          <!------------ END: Folder icon / Collapse action ------------>
          <!------------ START: Status icon ------------>
          <i
            v-if="!processTableEntry.children.length"
            v-b-popover.hover.top="
              getStatusTooltipText(
                processTableEntry.process?.last_execution_status
              )
            "
            class="fa fa-circle ml-1"
            :class="
              getStatusIcon(processTableEntry.process?.last_execution_status)
            "
          />
          <!------------ END: Status icon ------------>
        </div>
        <!------------ START: Process details ------------>
        <div class="d-flex flex-column flex-nowrap">
          <!------------ START: Process name ------------>
          <div class="pl-2 pt-3 lead">
            <span class="h6">
              {{ processTableEntry.label }}
            </span>
          </div>
          <!------------ END: Process name ------------>
          <!------------ START: Process status ------------>
          <div class="d-flex flex-row pl-2">
            <span class="text-muted pr-3 label">
              {{
                $t(
                  "general." +
                    (processTableEntry.process?.is_active
                      ? "active"
                      : "inactive")
                )
              }}
            </span>
            <span class="text-muted pr-3">
              {{ $t("table.queue") }}:
              {{ getQueueStatus(processTableEntry.process) }}
            </span>
            <span class="text-muted pr-3">
              {{ $t("table.lastRun") }}:
              {{
                processTableEntry.process?.last_run
                  ? formatTableDate(processTableEntry.process.last_run)
                  : "-"
              }}
            </span>
            <span class="text-muted pr-3">
              {{ $t("table.schedule") }}:
              {{
                getScheduleLabel(
                  processTableEntry.process?.schedule_method,
                  processTableEntry.process?.schedule_config
                )
              }}
            </span>
          </div>
          <!------------ END: Process status ------------>
        </div>
        <!------------ END: Process details ------------>
        <!------------ START: Actions ------------>
        <div class="ml-auto py-3 pr-3">
          <button
            v-b-popover.hover.top="$t('workflowDesigner.openReporting')"
            class="btn btn-icon btn-sm mr-1 btn-custom"
            @click.left="routeTo('reporting', processTableEntry.process.id)"
            @click.middle="routeTo('reporting', processTableEntry.process.id)"
          >
            <i class="fal fa-search" />
          </button>
          <button
            v-b-popover.hover.top="$t('workflowDesigner.openDesigner')"
            class="btn btn-icon btn-sm mr-1 btn-custom"
            @click.left="routeTo('designer', processTableEntry.process.id)"
            @click.middle="routeTo('designer', processTableEntry.process.id)"
          >
            <i class="fal fa-paint-brush" />
          </button>
          <button
            v-b-popover.hover.top="$t('workflowDesigner.edit')"
            class="btn btn-icon btn-sm mr-1 btn-custom"
            @click.left="routeTo('detail', processTableEntry.process.id)"
            @click.middle="routeTo('detail', processTableEntry.process.id)"
          >
            <i class="fal fa-pen" />
          </button>
          <button
            v-b-popover.hover.top="$t('workflowDesigner.workflowExport')"
            class="btn btn-icon btn-sm mr-1 btn-custom"
            @click="showExportModal(processTableEntry.process)"
          >
            <i class="fal fa-download" />
          </button>
          <!------------ START: Dropdown menu ------------>
          <v-menu
            :attach="'.li-' + processTableEntry.id ?? processTableEntry['id']"
            left
            offset-overflow
            offset-y
          >
            <template #activator="{ on, attrs }">
              <button
                class="btn btn-icon btn-sm btn-custom"
                v-bind="attrs"
                v-on="on"
              >
                <i class="fal fa-ellipsis-v" />
              </button>
            </template>
            <ul class="navi navi-hover p-0">
              <li
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="showTestWorkflowModal(processTableEntry.process)"
                >
                  <span class="navi-text">
                    {{ $t("workflowDesigner.workflowStart") }}
                  </span>
                </a>
              </li>
              <li
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="cancelWorkflow(processTableEntry.process)"
                >
                  <span class="navi-text">
                    {{ $t("workflowDesigner.workflowCancel") }}
                  </span>
                </a>
              </li>
              <li
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="openRequeueModal(processTableEntry.process)"
                >
                  <span class="navi-text">
                    {{ $t("workflowDesigner.requeue") }}
                  </span>
                </a>
              </li>
              <li
                v-if="!isPresetProject && isDevPresetVersion"
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="
                    deleteWorkflow(
                      processTableEntry.process,
                      processTableEntry.id
                    )
                  "
                >
                  <span class="navi-text">
                    {{ $t("workflowDesigner.deleteWorkflow") }}
                  </span>
                </a>
              </li>
              <li
                aria-haspopup="true"
                class="navi-item"
                data-menu-toggle="hover"
              >
                <a
                  class="navi-link"
                  @click="showVersions(processTableEntry.process)"
                >
                  <span class="navi-text">
                    {{ $t("general.versions") }}
                  </span>
                </a>
              </li>
            </ul>
          </v-menu>
          <!------------ END: Dropdown menu ------------>
        </div>
        <!------------ END: Actions ------------>
      </div>
      <!------------ END: Process entry ------------>
      <!------------ START: Children ------------>
      <processEntry-draggable
        v-show="!processTableEntriesConfig?.[processTableEntry?.id]?.collapsed"
        :list="processTableEntry.children"
        :parent-index="value ? index : parentIndex"
        :level="level + 1"
        @show-export-modal="showExportModal"
        @show-test-workflow-modal="showTestWorkflowModal"
        @show-versions="showVersions"
        @open-requeue-modal="openRequeueModal"
        @reload-workflows="reloadWorkflows"
      />
      <!------------ END: Children ------------>
    </li>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { formatDate } from "@/components/Tools/helperFunctions";
import Swal from "sweetalert2";
import FlowTableEntries from "@/components/Workflows/Designer/Components/flowTableEntries";
import {
  DELETE_TABLE_ENTRY,
  DELETE_TABLE_ENTRY_GROUP
} from "@/core/services/store/process.module";
import cronstrue from "cronstrue/i18n";
import processManager from "@/components/Workflows/processManager";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Workflows from "@/components/Workflows/Designer/workflows";
import ProcessManager from "@/components/Workflows/processManager";
import {
  getStatusColor,
  getStatusIcon,
  getStatusTooltipText
} from "@/components/Workflows/Reporting/utils";
import {
  removeCollapseProcessTableEntry,
  toggleCollapseProcessTableEntry
} from "@/components/Workflows/Designer/Components/ProcessTableEntry/useProcessTableEntries";
import { mapGetters } from "vuex";
import { useStore } from "@/core/services/store";

export default {
  name: "processEntry-draggable",
  components: {
    draggable
  },
  props: {
    value: {
      required: false,
      type: [Object, Array],
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    parentIndex: {
      required: false,
      type: Number,
      default: null
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isCollapsed: false
    };
  },
  computed: {
    ...mapGetters("processTableEntries", ["processTableEntriesConfig"]),
    ...mapGetters(["isPresetProject", "isDevPresetVersion"]),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    realValue() {
      return this.value ? this.value : this.list;
    }
  },
  mounted() {},
  methods: {
    getStatusTooltipText,
    getStatusIcon,
    getStatusColor,
    emitter(value) {
      this.$emit("input", value);
    },
    updateOrderIndexes() {
      let groups = this.flattenArray(this.$store.getters.processTableEntries);
      FlowTableEntries.order({ processTable: groups })
        .then(() => {})
        .catch(error => {
          this.$error(error);
        });
      this.$nextTick().then(() => this.setVisualCollapseStatus());
    },
    flattenArray(arr, parentId = null, index = 0) {
      let result = [];
      arr.forEach(element => {
        result.push({
          id: element.id,
          process_id: element.workflow_id,
          parent_id: parentId,
          order_index: index
        });
        index++;
        if (element.children.length > 0) {
          result = result.concat(
            this.flattenArray(element.children, element.id, index++)
          );
        }
      });
      return result;
    },
    toggleChildren(processTableEntry, toggleStore = true) {
      if (toggleStore) {
        toggleCollapseProcessTableEntry(processTableEntry.id);
      }

      const parent = document.getElementById(processTableEntry.id);
      const children = parent.querySelector(".dragArea");

      if (this.processTableEntriesConfig[processTableEntry.id]?.collapsed) {
        children.classList.add("d-none");
      } else {
        children.classList.remove("d-none");
      }
    },
    setVisualCollapseStatus() {
      const groupEntries = this.realValue.filter(
        entry => entry.children.length > 0
      );

      if (groupEntries.length === 0) return;

      groupEntries.forEach(entry => {
        const entryId = entry.id;
        const parent = document.getElementById(entryId);
        if (!parent) return;
        const children = parent.querySelector(".dragArea");

        if (this.processTableEntriesConfig[entryId]?.collapsed) {
          children.classList.add("d-none");
        } else {
          children.classList.remove("d-none");
        }
      });
    },
    getDescription(children) {
      let { labels, counter } = this.getGroupChildren(children);

      let text = labels.join(", ");

      if (counter > 0) {
        text += this.$t("workflowDesigner.xMoreProcesses", { number: counter });
      }

      return text;
    },
    getGroupChildren(children, labels = [], counter = 0) {
      children.forEach(child => {
        if (child.type === "process") {
          labels.length > 4 ? counter++ : labels.push(child.label);
        }

        if (child.children.length) {
          let result = this.getGroupChildren(child.children, labels, counter);

          labels = result.labels;
          counter = result.counter;
        }
      });

      return { labels, counter };
    },
    editGroup(group) {
      Swal.fire({
        title: this.$t("workflowDesigner.editGroup", { queue: name }),
        icon: "info",
        input: "text",
        inputValue: group.label,
        confirmButtonColor: "#5b64ee",
        confirmButtonText: this.$t("general.save")
      }).then(result => {
        if (result.isConfirmed) {
          const label = result.value;
          const id = group.id;
          FlowTableEntries.put({ id, label })
            .then(() => {
              group.label = label;
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    },
    deleteGroup(groupId, groupName) {
      let params = {
        title: this.$t("workflowDesigner.deleteGroupTitle"),
        text: this.$t("workflowDesigner.deleteGroupText", { title: groupName }),
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;

        addEventToLoadingQueue({ key: "deleteGroup" });
        FlowTableEntries.remove(groupId)
          .then(() => {
            this.$store.dispatch(DELETE_TABLE_ENTRY_GROUP, groupId);
            removeCollapseProcessTableEntry(groupId);
          })
          .catch(error => {
            this.$error(error);
          })
          .finally(() => {
            removeEventFromLoadingQueue({ key: "deleteGroup" });
          });
      });
    },
    formatTableDate(date) {
      return formatDate(date);
    },
    getScheduleLabel(method, config) {
      if (!(config && Object.keys(config).length && config[0] !== null)) {
        return method ? this.$t("scheduleMethods." + method) : " -";
      }

      if (method === "cron" && config.expression.includes("*")) {
        let locale = this.$store.state.profile.language;
        return cronstrue.toString(config.expression, { locale: locale });
      }
      let configLabeled = {};
      Object.keys(config).forEach(param => {
        let value = config[param];
        let days = [];
        switch (param) {
          case "day":
            configLabeled[param] = this.$t("weekdays." + value);
            break;
          case "days":
            value.forEach(day => {
              days.push(this.$t("weekdays." + day));
            });
            configLabeled[param] = days.join(", ");
            break;
          case "first":
            configLabeled[param] =
              method === "twiceMonthly" ? this.$t("weekdays." + value) : value;
            break;
          case "second":
            configLabeled[param] =
              method === "twiceMonthly" ? this.$t("weekdays." + value) : value;
            break;
          default:
            configLabeled[param] = value;
            break;
        }
      });
      return this.$t("scheduleMethods." + method + "WithParams", configLabeled);
    },
    getQueueStatus(progress) {
      return progress?.queue_name ?? "-";
    },
    showExportModal(workflow) {
      this.$emit("show-export-modal", workflow);
    },
    showTestWorkflowModal(workflow) {
      this.$emit("show-test-workflow-modal", workflow);
    },
    showVersions(workflow) {
      this.$emit("show-versions", workflow);
    },
    openRequeueModal(workflow) {
      this.$emit("open-requeue-modal", workflow);
    },
    routeTo(type, id) {
      switch (type) {
        case "reporting":
          this.$router.push({
            name: "projectWorkflowsReportingDetails",
            params: { id: id }
          });
          break;
        case "designer":
          this.$router.push({
            name: "projectWorkflowsEditor",
            params: { id: id }
          });
          break;
        case "code":
          this.$router.push({
            name: "projectWorkflowsEditorMode",
            params: { id: id, mode: "code" }
          });
          break;
        case "journal":
          this.$router.push({
            name: "projectAnalysisJournal"
          });
          break;
        case "detail":
          this.$router.push({
            name: "projectWorkflowsDetailBaseData",
            params: { id: id }
          });
          break;
        default:
          break;
      }
    },
    cancelWorkflow(process) {
      let params = {
        title: this.$t("queuedJobs.confirmBulkDelete"),
        text: this.$t("processManager.bulkDeleteProcessIdJobs", {
          process: process.name
        }),
        cancelButtonText: this.$t("processManager.noCancel"),
        confirmButtonText: this.$t("processManager.yesCancel")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;

        addEventToLoadingQueue({ key: "cancelWorkflow" });
        processManager
          .abort(process.id)
          .then(response => {
            if (response.data.success) {
              removeEventFromLoadingQueue({
                key: "cancelWorkflow",
                type: "success",
                prefix: "queuedJobs",
                name: "selectedRowsDeleted",
                data: {
                  count: response.data.removed
                }
              });
            } else {
              removeEventFromLoadingQueue({
                key: "cancelWorkflow"
              });
            }
          })
          .catch(error => {
            this.$error(error);
          });
      });
    },
    deleteWorkflow(workflow, processTableId) {
      let params = {
        title: this.$t("workflowDesigner.dangerZoneDeleteTitle"),
        text: this.$t("workflowDesigner.dangerZoneDeleteText", {
          workflow: workflow.name
        })
      };
      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;
        addEventToLoadingQueue({ key: "deleteWorkflow" });

        let request;
        if (workflow?.workflow) {
          request = this.deleteWorkflowEntry(workflow.workflow_id);
        } else {
          request = this.deleteProcessEntry(workflow.id);
        }

        request
          .then(response => {
            if (!response.data.success) {
              return this.showRelatedEntitiesError(response.data);
            }
            const store = useStore();
            store.dispatch(DELETE_TABLE_ENTRY, processTableId);
          })
          .catch(error => {
            this.$error(error);
          })
          .finally(() => {
            removeEventFromLoadingQueue({ key: "deleteWorkflow" });
          });
      });
    },
    deleteWorkflowEntry(workflowId) {
      return Workflows.delete(workflowId);
    },
    deleteProcessEntry(processId) {
      return ProcessManager.delete(processId);
    },
    showRelatedEntitiesError(response) {
      const errorMessage = response.error;
      const relatedItems = response.related;
      let details = "";

      for (const key in relatedItems) {
        if (Object.prototype.hasOwnProperty.call(relatedItems, key)) {
          relatedItems[key].forEach(item => {
            details += `<b>Included in Workflow ID:</b> <li>${item.workflow_id}</li>`;
            details += "<br>";
            details += `<i class="fa fa-arrow-turn-down-right"></i>${item.workflow_element_name} <br>`;
          });
        }
      }

      this.$swal.fire({
        icon: "error",
        title: errorMessage,
        html: details
      });
    },
    reloadWorkflows() {
      this.$emit("reload-workflows");
    }
  }
};
</script>

<style scoped lang="scss">
li {
  border-left: 1px dashed lighten($color-connect-primary-medium, 3);
  background-color: #fff;
  list-style-type: none;
  position: relative;
}

// Adds recursive background color to li elements
li:not(:has(li:hover)):hover {
  background-color: #f8f9fc;

  li {
    background-color: #f8f9fc;
  }
}

.v-menu__content {
  li {
    border: none;
  }
}

.col-12 {
  .dragArea > li {
    border-top: 1px solid $color-connect-primary-medium;
  }
}

.dropdown-menu {
  padding-left: 0 !important;

  li {
    background-color: #ffffff !important;
    border: none !important;

    &:hover {
      background-color: #ffffff !important;
    }

    &.navi-item {
      a {
        border-radius: 6px;
      }
    }
  }
}

.btn-custom {
  border-radius: 6px;
  i {
    color: $color-connect-text;
  }

  &:hover {
    opacity: 0.8;
  }
}

.dragArea {
  min-height: 3rem;
  padding-right: 0;
  padding-bottom: 0;

  ul:empty {
    min-height: 1rem;
  }

  &:nth-child(2) {
    margin-left: 25px;
  }

  .groupChildren {
    margin-left: 20px;
  }

  &.level-0 {
    > li {
      border-left: none;
    }

    > li:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-top: none;
    }

    > li:last-child {
      border-bottom-left-radius: 12px;
    }
  }
  li:last-child {
    border-bottom-right-radius: 12px;
  }
}

.ghost {
  background-color: $color-connect-primary-medium !important;
  border: 2px dashed $color-connect-primary !important;
  font-size: 0;
  overflow: hidden;

  li {
    background-color: $color-connect-primary-medium !important;
    border: 1px solid transparent;
  }
}

.group-description-text {
  font-size: 1rem;
}

.v-application {
  ul {
    &.dropdown-menu {
      padding-left: 0;
    }
  }
}
</style>
