import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class WorkflowManager {
  export(id, type) {
    if (type === "json") {
      return ApiService.post(url + "/export/json/workflow/" + id);
    } else if (type === "yaml") {
      return ApiService.post(url + "/export/yaml/workflow/" + id);
    } else if (type === "yml") {
      return ApiService.post(url + "/export/yml/workflow/" + id);
    }
  }

  import(data) {
    // FormData needs to be sent without a content type
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(url + "/import", data, {}, true);
  }
}

export default new WorkflowManager();
