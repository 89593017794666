<template>
  <div>
    <TableHelper
      ref="table"
      :title="$t('reporting.childJobsFrom', { process: process.name })"
      :items="iterations"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      param-prefix="jobs-table"
      :no-route-params="true"
      :enable-filter="false"
      loading-key="loadJobsIterations"
      :progress-bar-key="isModal ? 'loadingSidebar' : null"
      group-key="loadJobsIterations"
      @reload-data="loadJobsIterations"
      @open-reporting="routeToLogs"
    />
  </div>
</template>

<script>
import ProcessIteration from "@/components/Workflows/processIteration";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { TableHelper },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    process: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      iterations: [],
      searchTitle: "",
      parentJobId: null,
      fields: [
        {
          key: "content_identifier",
          label: this.$t("table.contentIdentifier"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          thClass: "text-center",
          thStyle: { width: "80px" },
          type: "status"
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          thStyle: { width: "120px" },
          type: "time"
        }
      ],
      actions: [
        {
          key: "openReporting",
          icon: "fal fa-search",
          emit: "open-reporting",
          tooltip: this.$t("workflowDesigner.openReporting")
        }
      ],
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"])
  },
  watch: {
    "$route.params.jobId": {
      handler: function (jobId) {
        if (jobId !== this.jobId) {
          this.parentJobId = this.$route.params.jobId;
          this.loadJobsIterations();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.parentJobId = this.$route.params.jobId;
  },
  methods: {
    loadJobsIterations() {
      let eventParams = {
        key: "loadJobsIterations"
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }
      addEventToLoadingQueue(eventParams);

      let params = this.requestParams();
      ProcessIteration.getByParentIteration(this.parentJobId, params)
        .then(response => {
          this.iterations = response.data;
          this.meta = response.meta;
          this.$emit("iterations-loaded", this.iterations.length);
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    requeueJob(item) {
      let eventParams = {
        key: "requeueJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);

      ProcessIteration.requeue(item.item.id)
        .then(() => {
          removeEventFromLoadingQueue({
            key: eventParams.key,
            progressBarKey: eventParams.progressBarKey,
            type: "success",
            prefix: "processManager",
            name: "jobRequeued"
          });

          this.loadJobsIterations();
        })
        .catch(error => {
          this.$error(error);
          this.loadJobsIterations();
        });
    },
    routeToLogs(item) {
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: item.item.process_id, jobId: item.item.id }
      });
    }
  }
};
</script>

<style scoped></style>
