var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-grid"},[_c('Header',{attrs:{"title":_vm.$t('workflowDesigner.workflowDesignerTable').toString(),"items":_vm.headerItems},on:{"reload-workflows":_vm.reloadWorkflows,"create-workflow":_vm.createWorkflow,"add-process-group":_vm.addProcessGroup,"collapse-all":_vm.collapseAll,"collapse-none":_vm.collapseNone},scopedSlots:_vm._u([(!_vm.isPresetProject && _vm.isDevPresetVersion)?{key:"afterItems",fn:function(){return [_c('file-upload',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.$t('workflowDesigner.workflowImportHelp')),expression:"$t('workflowDesigner.workflowImportHelp')",modifiers:{"hover":true,"top":true}}],ref:"upload",staticClass:"btn btn-outline-primary btn-hover ml-2",attrs:{"extensions":"json,yaml,yml","accept":"application/json,.yml,.yaml"},on:{"input-file":_vm.inputFile}},[_c('i',{staticClass:"fa-light fa-file-import"})])]},proxy:true}:null],null,true)}),(
      !_vm.$store.getters['loadingQueue/showLoadingBar']() &&
      _vm.$store.getters.processTableEntries.length < 1
    )?_c('div',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"w-50"},[_c('Card',[_c('div',{staticClass:"text-hover-primary cursor-pointer p-6",on:{"click":function($event){return _vm.$router.push({ name: 'projectWorkflowsCreate' })}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('img',{staticClass:"w-100",attrs:{"alt":"Workflow","src":"media/img/workflow.png"}})]),_c('div',{staticClass:"col-8 text-center d-flex align-center justify-center"},[_c('div',[_c('i',{staticClass:"fal fa-plus-circle mb-6",staticStyle:{"font-size":"3.5rem"}}),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("workflowDesigner.createNewWorkflow"))+" ")])])])])])])],1)])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$store.getters.processTableEntries.length > 0 ||
      _vm.$store.getters['loadingQueue/showLoadingBar']()
    ),expression:"\n      $store.getters.processTableEntries.length > 0 ||\n      $store.getters['loadingQueue/showLoadingBar']()\n    "}],staticClass:"workflow-listing"},[_c('ProjectWorkflow',{ref:"projectWorkflow"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }