import { useStore } from "@/core/services/store";

// Get access to store
const store = useStore();

export const toggleCollapseProcessTableEntry = payload => {
  store.dispatch(
    "processTableEntries/toggleCollapseProcessTableEntry",
    payload
  );
};

export const addCollapseProcessTableEntry = payload => {
  store.dispatch("processTableEntries/addCollapseProcessTableEntry", payload);
};

export const removeCollapseProcessTableEntry = payload => {
  store.dispatch(
    "processTableEntries/removeCollapseProcessTableEntry",
    payload
  );
};
