<template>
  <div>
    <Header
      :show-back-button="true"
      :title="
        $t('workflowDesigner.reportingHeader', {
          workflow: process.name
        }).toString()
      "
      @back="back"
    >
      <template #beforeItems>
        <button
          v-if="!isModal"
          v-b-tooltip.top.noninteractive="$t('workflowDesigner.openDesigner')"
          class="btn-icon btn-sm btn-hover-light-primary mr-2"
          @click="toDesigner"
          @click.middle="toDesigner"
        >
          <i class="fal fa-paint-brush" />
        </button>
      </template>
    </Header>
    <TableHelper
      ref="jobsTable"
      :actions="actions"
      :buttons="buttons"
      :enable-filter="true"
      :exportable="true"
      :fields="fields"
      :filterable="filterable"
      :filterable-field-blacklist="filterableFieldBlacklist"
      :items="iterations"
      :meta="meta"
      :no-route-params="true"
      :param-prefix="paramPrefix"
      :progress-bar-key="isModal ? 'loadingSidebar' : null"
      loading-key="loadIterations"
      @reload-data="loadIterations"
      @open-reporting="showDetailsSidebar"
      @requeue-job="requeueJob"
      @resume-job="resumeJob"
      @cancel-job="cancelJob"
      @export-csv="onExportCsv"
      @requeue-selected="requeueSelected"
    />
    <b-sidebar
      id="jobDetailSidebar"
      ref="jobDetailsSidebar"
      v-model="jobDetailsSidebar"
      lazy
      no-close-on-route-change
      no-header
      right
      width="100%"
    >
      <Progressbar
        key="loadingSidebar"
        class="position-relative"
        progress-bar-key="loadingSidebar"
      />
      <div class="px-6 py-4">
        <JobDetails
          :is-modal="isModal"
          :selected-job-detail-id="selectedJobDetailId"
          @close-modal="closeModal"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Reporting from "@/components/Workflows/Reporting/reporting";
import ProcessManager from "@/components/Workflows/processManager";
import { formatDate } from "@/components/Tools/helperFunctions";
import ProcessIteration from "@/components/Workflows/processIteration";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import JobDetails from "@/components/Workflows/Reporting/Index.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import Header from "@/components/Tools/Header/Header.vue";

export default {
  name: "reportingJobs",
  components: {
    Header,
    Progressbar,
    TableHelper,
    JobDetails
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttons: [
        {
          key: "requeueSelected",
          icon: "fal fa-reply",
          emit: "requeue-selected",
          tooltip: this.$t("processManager.requeueSelected")
        }
      ],
      jobDetailsSidebar: false,
      selectedJobDetailId: null,
      process: {},
      iterations: [],
      actions: [
        {
          key: "openJobReporting",
          icon: "fal fa-search",
          emit: "open-reporting",
          tooltip: this.$t("workflowDesigner.openReporting")
        },
        {
          key: "requeueJob",
          icon: "fal fa-reply",
          emit: "requeue-job",
          tooltip: this.$t("processManager.requeue")
        },
        {
          key: "resumeJob",
          icon: "fal fa-play",
          emit: "resume-job",
          tooltip: this.$t("processManager.resumeJob"),
          condition: {
            field: "status",
            operator: "===",
            value: "process.paused"
          }
        },
        {
          key: "cancelJob",
          icon: "fal fa-times-circle",
          emit: "cancel-job",
          tooltip: this.$t("processManager.jobCancel"),
          condition: {
            field: "status",
            operator: "includes",
            value: [
              "process.info",
              "process.paused",
              "process.pending",
              "process.working",
              "process.soft_error",
              "process.started",
              "process.waiting",
              "process.warning"
            ]
          }
        }
      ],
      fields: [
        {
          key: "content_identifier",
          label: this.$t("table.contentIdentifier"),
          sortable: true
        },
        {
          key: "case_identifier",
          label: this.$t("table.caseIdentifier"),
          sortable: true
        },
        {
          key: "progress",
          label: this.$t("table.progress"),
          thClass: "text-center",
          tdClass: "text-left p-0 m-0",
          sortable: true,
          type: "progress"
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          thClass: "text-center",
          thStyle: { width: "100px" },
          type: "status"
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          type: "time"
        },
        {
          key: "created_at",
          label: this.$t("table.dispatched"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "finished_at",
          label: this.$t("table.end"),
          sortable: true,
          type: "datetime"
        }
      ],
      meta: {},
      paramPrefix: "reporting-jobs",
      // Filterable fields
      filterable: {},
      filterableFieldBlacklist: [
        "project_id",
        "process_id",
        "_id",
        "parent_flow_execution_id",
        "parameters",
        "queue",
        "warnings_count"
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters(["userData"]),
    selectedPPID: function () {
      return this.$route.query.ppid ?? null;
    }
  },
  mounted() {
    this.loadProcess();
    this.loadIterations();
  },
  methods: {
    showDetailsSidebar(iterations) {
      if (!this.isModal) {
        this.routeToLogs(iterations.item.id);
        return;
      }

      this.selectedJobDetailId = iterations.item.id;
      this.$route.params.jobId = iterations.item.id;
      this.jobDetailsSidebar = true;
    },
    showDetailsSidebarForJob(jobId) {
      this.selectedJobDetailId = jobId;
      this.$route.params.jobId = jobId;
      this.jobDetailsSidebar = true;
    },
    formatDate,
    loadProcess() {
      let eventParams = {
        key: "loadProcess",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      this.process = {};
      let id = this.$route.params.id;
      ProcessManager.get(id, true)
        .then(response => {
          this.process = response.data;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    loadIterations(payload = {}) {
      let eventParams = {
        key: "loadIterations",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);

      let processId = this.$route.params.id;
      let params = { ...this.params(this.paramPrefix), ...payload };

      if (!params?.filter) {
        params.filter = [];
      }

      if (this.selectedPPID) {
        params.filter.push({
          key: "parent_flow_execution_id",
          op: "equals",
          value: this.$route.query.ppid
        });
      }

      this.processStatusFilterWithWarning(params);

      Reporting.getIterations(processId, params)
        .then(response => {
          this.iterations = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable;
          removeEventFromLoadingQueue(eventParams);
        })
        .catch(error => {
          this.$error(error);
        });
    },
    /**
     * The status process.warning is not set on the iteration, but on the logs inside the iteration.
     * Therefore, we need to remove process.warning from the status filter and add a filter for warnings_count.
     * @param params
     */
    processStatusFilterWithWarning(params) {
      const warningStatus = "process.warning";

      const statusFilterWithWarning = params.filter.filter(
        el => el.key === "status" && el.value.includes(warningStatus)
      );

      for (const item of statusFilterWithWarning || []) {
        item.value = item.value.filter(val => val !== warningStatus);

        const warningsOp = item.op === "in" ? "greaterThan" : "equals";

        params.filter.push({
          key: "warnings_count",
          op: warningsOp,
          value: 0
        });
      }
    },
    resumeJob(iterations) {
      let eventParams = {
        key: "resumeJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      ProcessIteration.resume(iterations.item.id)
        .then(() => {
          removeEventFromLoadingQueue({
            key: eventParams.key,
            progressBarKey: eventParams.progressBarKey,
            type: "success",
            prefix: "processManager",
            name: "jobResumed"
          });
          this.loadIterations();
        })
        .catch(error => {
          this.$error(error);
          this.loadIterations();
        });
    },
    requeueJob(iterations) {
      let eventParams = {
        key: "requeueJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      ProcessIteration.requeue(iterations.item.id)
        .then(iteration => {
          removeEventFromLoadingQueue({
            key: eventParams.key,
            progressBarKey: eventParams.progressBarKey,
            type: "success",
            prefix: "processManager",
            name: "jobRequeued"
          });

          this.$toast
            .fire({
              icon: "success",
              title: this.$t("processManager.processTestStarted"),
              showConfirmButton: true,
              confirmButtonColor: "#5b64ee",
              confirmButtonText: this.$t("processManager.openReporting")
            })
            .then(result => {
              if (!result.isConfirmed) {
                return;
              }
              this.$router.push({
                name: "projectWorkflowsJobDetails",
                params: {
                  id: iteration.data.process_id,
                  jobId: iteration.data.id
                }
              });
            });

          this.loadIterations();
        })
        .catch(error => {
          this.$error(error);
          this.loadIterations();
        });
    },
    cancelJob(iteration) {
      const statuses = ["process.error", "process.stopped", "process.aborted"];

      if (statuses.includes(iteration.item.status)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("queuedJobs.jobAbortFailed", {
            status: iteration.item.status
          })
        });
        return;
      }

      Swal.fire({
        title: this.$t("queuedJobs.deleteConfirm"),
        text: this.$t("queuedJobs.deleteConfirmText", {
          queue: iteration.item.process_name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#5b64ee",
        showCloseButton: true,
        cancelButtonText: this.$t("queuedJobs.noCancel"),
        confirmButtonText: this.$t("queuedJobs.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "cancelJob" });
          ProcessIteration.abort(iteration.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "cancelJob",
                type: "success",
                prefix: "queuedJobs",
                name: "cancelSuccessText",
                data: {
                  queue: iteration.item.process_name
                }
              });
              this.loadIterations();
            })
            .catch(error => {
              this.$error(error);
              this.loadIterations();
            });
        }
      });
    },
    onExportCsv(params) {
      const processId = this.$route.params.id;
      ProcessIteration.exportCsv(processId, params).catch(error => {
        this.$error(error);
      });
    },
    requeueSelected() {
      const ids = this.$refs.jobsTable.selectedRows.map(row => row.id);
      if (!ids.length) {
        return;
      }
      const data = {
        process_id: this.$route.params.id,
        flow_execution_ids: ids
      };
      addEventToLoadingQueue({ key: "requeueSelected" });
      ProcessIteration.bulkRequeue(data)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "requeueSelected",
            type: "success",
            prefix: "queuedJobs",
            name: "requeueSelectedSuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.loadIterations();
        });
    },
    routeToLogs(jobId) {
      let processId = this.$route.params.id;
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: processId, jobId: jobId }
      });
    },
    toDesigner() {
      this.$router.push({
        name: "projectWorkflowsEditor",
        params: { id: this.process.id }
      });
    },
    back() {
      if (!this.isModal) {
        this.$router.push({ name: "projectWorkflows" });
      } else {
        this.$emit("closeModal");
      }
    },
    closeModal() {
      this.jobDetailsSidebar = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-header {
  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.v-select__selections {
  height: 55px;
}

:deep(.v-dialog) {
  height: 90vh;
}
</style>
