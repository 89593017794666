import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class ProcessLogs extends BaseService {
  getAll(params, flat = false) {
    let data = {
      page: params.page,
      perPage: params.perPage,
      flat: flat,
      searchTerm: params.searchTerm,
      filter: params.filter
    };
    let headers = {};
    return ApiService.post(url + "/process_logs", data, headers);
  }
  get(id) {
    return ApiService.get(url, "process_logs/" + id);
  }
  exportCsv(params) {
    return ApiService.post(url + "/process_logs/export_csv", params);
  }
}
export default new ProcessLogs();
