import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class FlowTableEntries extends BaseService {
  getAll(
    params,
    filter = [],
    fields = ["id", "children", "label", "process", "type"]
  ) {
    let filterParams = this.getParams(filter, {}, fields);
    let searchParams = new URLSearchParams(params);

    let options = "?" + searchParams.toString();
    if (options === "?") {
      filterParams = filterParams.substring(1);
    }
    return ApiService.get(url, "flow_table_entries" + options + filterParams);
  }
  order(data) {
    return ApiService.put(url + "/flow_table_entries/order", data);
  }
  put(data) {
    let slug = "/flow_table_entries/";
    return ApiService.put(url + slug + data.id, data);
  }
  remove(id) {
    return ApiService.delete(url + "/flow_table_entries/" + id);
  }
  create(project_id, label) {
    return ApiService.post(
      url + "/flow_table_entries/createGroup",
      project_id,
      label
    );
  }
}
export default new FlowTableEntries();
