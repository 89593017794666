<template>
  <div>
    <b-row class="align-self-stretch">
      <b-col md="4" class="pr-1">
        <Card class="h-100">
          <div class="card-body">
            <div class="h5 font-weight-bolder text-dark">
              {{ $t("reporting.completeTimeEvaluation") }}
            </div>
            <b-row class="mt-3">
              <b-col>
                {{ $t("reporting.contentIdentifier") }}
              </b-col>
              <b-col>
                {{ job.content_identifier }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.caseIdentifier") }}
              </b-col>
              <b-col>
                {{ job.case_identifier }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.status") }}
              </b-col>
              <b-col>
                <i
                  v-b-popover.hover.top="getStatusTooltipText(job.status)"
                  :class="getStatusIcon(job.status)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.startedBy") }}
              </b-col>
              <b-col>
                <a
                  v-if="
                    job.parent_process_iteration !== undefined &&
                    job.parent_process_iteration !== null
                  "
                  class="text-blue"
                  @click="routeToParentIteration"
                  >{{ job.started_by }}</a
                >
                <template v-else>{{ job.started_by }}</template>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.startedAt") }}
              </b-col>
              <b-col>
                {{ formatDate(job.started_at) }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.finishedAt") }}
              </b-col>
              <b-col>
                {{ formatDate(job.finished_at) }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.duration") }}
              </b-col>
              <b-col>
                <span v-if="calculateDuration(job)">
                  {{ millisecondsToTime(calculateDuration(job)) }}
                </span>
                <span v-else-if="calculateDuration(job) === 0">
                  {{ $t("reporting.durationApproximately") }}
                </span>
                <span v-else> --:-- </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t("reporting.queue") }}
              </b-col>
              <b-col>
                {{ queueName }}
              </b-col>
            </b-row>
          </div>
        </Card>
      </b-col>
      <b-col md="4" class="px-1">
        <Card class="h-100">
          <div class="card-body">
            <div class="h5 font-weight-bolder text-dark mb-3">
              {{ $t("reporting.parameter") }}
            </div>
            <FormHelper
              key="1"
              v-model="formValues"
              :form="parameter"
              :config="config"
            />
          </div>
        </Card>
      </b-col>
      <b-col md="4" class="pl-1">
        <Card class="h-100">
          <div class="card-body">
            <div class="h5 font-weight-bolder text-dark mb-3">
              {{ $t("reporting.returnValues") }}
            </div>
            <FormHelper
              key="2"
              v-model="formValues"
              :form="returnValue"
              :config="config"
            />
          </div>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  calculateDuration,
  formatDate,
  millisecondsToTime
} from "@/components/Tools/helperFunctions";
import {
  getStatusIcon,
  getStatusTooltipText
} from "@/components/Workflows/Reporting/utils";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Card from "@/components/Tools/Card/Card.vue";

export default {
  components: {
    Card,
    FormHelper
  },
  props: {
    process: {
      type: Object,
      default: () => {}
    },
    job: {
      type: Object,
      default: () => {}
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {
        labelStacked: true
      },
      parameter: [
        {
          name: "parameters",
          type: "code",
          lang: "json",
          style: "dark",
          readOnly: true,
          disabled: true
        }
      ],
      returnValue: [
        {
          name: "returnValue",
          type: "code",
          lang: "json",
          style: "dark",
          readOnly: true,
          disabled: true
        }
      ],
      formValues: {
        parameters: "",
        returnValue: null
      },
      autoLoading: true
    };
  },
  computed: {
    queueName: function () {
      if (this.job.queue === undefined || this.job.queue === null) {
        return "";
      }
      return this.job.queue.split("-")[0];
    }
  },
  watch: {
    job: function () {
      this.formValues.parameters = JSON.stringify(this.job.parameters, null, 2);
      this.formValues.returnValue = JSON.stringify(this.job.return, null, 2);
    }
  },
  methods: {
    millisecondsToTime,
    formatDate,
    getStatusIcon,
    getStatusTooltipText,
    calculateDuration,
    routeToParentIteration() {
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: {
          id: this.job.parent_process_iteration.process_id,
          jobId: this.job.parent_process_iteration.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.col {
  padding-top: 0;
  padding-bottom: 0;
}

// Set height of "parameter"- and "return value"-card
:deep(.CodeMirror) {
  height: 133px;
  min-height: 133px;
  resize: vertical;
}

.text-blue {
  color: #5b64ee;
}
</style>
