<template>
  <b-sidebar
    id="workflowStartSidebar"
    ref="workflowStartSidebar"
    v-model="showSidebar"
    no-close-on-route-change
    backdrop
    lazy
    no-header
    right
    width="85%"
    @hidden="$emit('close')"
  >
    <Progressbar
      key="loadingSidebar"
      class="position-relative"
      progress-bar-key="loadingSidebar"
    />
    <div class="px-6 py-4">
      <Header :show-back-button="true" :title="title" @back="$emit('close')">
      </Header>
      <div class="row">
        <!--     START: LATEST JOBS     -->
        <div class="col-lg-7">
          <div class="text-h5 mb-5">
            {{ $t("workflowDesigner.testWorkflowTableTitle") }}
          </div>
          <TableHelper
            ref="table"
            :enable-filter="false"
            :fields="fields"
            :items="iterations"
            :meta="meta"
            select-mode="single"
            loading-key="loadIterations"
            progress-bar-key="loadingSidebar"
            @reload-data="loadIterations"
            @select-row="copyParameter"
          />
        </div>
        <!--     END: LATEST JOBS     -->
        <!--     START: PARAMETER     -->
        <div class="col-lg-5">
          <div class="test-workflow-editor-container sticky-top">
            <div class="text-h5">{{ $t("workflowDesigner.parameter") }}</div>
            <div>
              {{ $t("workflowDesigner.testWorkflowTableDescription") }}
            </div>
            <Card class="py-4">
              <FormHelper
                v-model="workflowParameters"
                :config="formConfig"
                :form="form"
              />
              <div class="px-3">
                <div class="d-flex justify-space-between">
                  <span class="switch">
                    <label class="mr-3">
                      <input
                        v-model="config.testWorkflowStart"
                        type="checkbox"
                        value="1"
                      />
                      <span></span>
                    </label>
                    {{ $t("workflowDesigner.workflowStart") }}
                  </span>
                  <span class="switch">
                    <label class="mr-3">
                      <input
                        v-model="config.triggerChildProcesses"
                        type="checkbox"
                        value="1"
                      />
                      <span></span>
                    </label>
                    {{ $t("workflowDesigner.triggerChildProcesses") }}
                  </span>
                  <div>
                    <button
                      :disabled="testWorkflowInvalidJson || workflowSubmitted"
                      class="btn btn-primary"
                      type="submit"
                      @click="runWorkflow"
                    >
                      <template v-if="!config.testWorkflowStart">
                        {{ $t("workflowDesigner.workflowTest") }}
                      </template>
                      <template v-else>{{
                        $t("workflowDesigner.workflowStart")
                      }}</template>
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <!--     END: PARAMETER     -->
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import ProcessIterations from "@/components/Workflows/processIteration";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Card from "@/components/Tools/Card/Card.vue";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";
import ProcessManager from "@/components/Workflows/processManager";
import { UPDATE_TEST_PROCESS_DATA } from "@/core/services/store/process.module";

export default {
  components: {
    Card,
    TableHelper,
    Progressbar,
    Header,
    FormHelper
  },
  props: {
    process: {
      type: Object,
      default: () => {}
    },
    workflowShowSidebar: {
      type: Boolean,
      default: false
    },
    testWorkflowStart: {
      type: Boolean,
      default: false
    },
    workflowElementsData: {
      type: Array,
      default: () => []
    },
    isInDesigner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSidebar: false,
      testWorkflowInvalidJson: false,
      workflowSubmitted: false,
      workflowParameters: {
        parameters: ""
      },
      fields: [
        {
          key: "started_at",
          label: this.$t("table.start"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          type: "time"
        },
        {
          key: "parameters",
          label: this.$t("table.parameter"),
          sortable: false
        }
      ],
      meta: {},
      iterations: [],
      form: [
        {
          type: "code",
          name: "parameters",
          lang: "json"
        }
      ],
      formCodeView: [
        {
          type: "code",
          name: "parameters",
          lang: "json",
          disabled: true
        }
      ],
      formConfig: {
        labelStacked: true
      },

      config: {
        testWorkflowStart: {
          type: Boolean,
          default: false
        },
        triggerChildProcesses: {
          type: Boolean,
          default: true
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      allElements: GET_ALL_ELEMENTS,
      requestParams: "route/requestParams"
    }),
    title: function () {
      if (!this.process) {
        return "";
      }
      return this.$t(
        this.config.testWorkflowStart
          ? "processManager.startWorkflow"
          : "processManager.testWorkflow",
        {
          name: this.process.name
        }
      );
    }
  },
  watch: {
    workflowParameters: function () {
      let testProcessData = this.$store.getters.testProcessData;

      testProcessData[this.uniqueProcessIdentifier] =
        this.workflowParameters.parameters;

      this.testWorkflowJsonValidator(this.workflowParameters);
    },
    workflowShowSidebar: function () {
      if (this.workflowShowSidebar) this.loadIterations();
      this.showSidebar = this.workflowShowSidebar;
    },
    process: {
      deep: true,
      handler: function (process) {
        let testProcessData = this.$store.getters.testProcessData;
        this.workflowParameters.parameters = testProcessData[process.id] ?? "";
      }
    }
  },
  mounted() {
    this.config.testWorkflowStart = this.testWorkflowStart;
  },
  methods: {
    copyParameter(item) {
      if (item.length < 1) {
        this.workflowParameters.parameters = "";
      } else {
        this.workflowParameters.parameters = JSON.stringify(item[0].parameters);
      }
    },
    loadIterations(payload = {}) {
      if (!this.process) {
        return;
      }

      let eventParams = {
        key: "loadIterations",
        progressBarKey: "loadingSidebar"
      };

      addEventToLoadingQueue(eventParams);
      let params = { ...this.requestParams(), ...payload };

      if (params.perPage && !params.size) params.size = params.perPage;
      params.filter = [
        {
          key: "process_id",
          op: "equals",
          value: this.process.id
        }
      ];
      params.hasParameters = true;

      ProcessIterations.getAll(params)
        .then(response => {
          this.iterations = response.data;
          this.iterations.forEach(entry => {
            delete entry?.parameters?._delay;
          });
          this.meta = response.meta;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    runWorkflow() {
      if (this.testWorkflowInvalidJson) return;
      this.workflowSubmitted = true;

      let eventParams = {
        key: "runWorkflow",
        progressBarKey: "loadingSidebar"
      };
      addEventToLoadingQueue(eventParams);

      let value = this.workflowParameters.parameters;
      let testProcessData = this.$store.getters.testProcessData;
      let jsonParams = { params: JSON.parse(value ? value : "{}") };

      testProcessData[this.process.id] = value;
      this.$store.dispatch(UPDATE_TEST_PROCESS_DATA, testProcessData);

      if (!this.config.triggerChildProcesses) {
        jsonParams.params.dispatchChildren = false;
      }

      /*
      The configured elements must be sent with the "test" endpoint,
      so you can test a workflow without having to save it, for example.
      When testing from the workflow overview, we do not have the elements,
      which is why the workflow is started there with the parameter "dispatchImmediately" when testing
       */
      let testWorkflow = false;
      if (!this.config.testWorkflowStart) {
        testWorkflow = this.workflowElementsData.length > 0;
        jsonParams.dispatchImmediately = !testWorkflow;
        jsonParams.configured_elements = this.workflowElementsData;
        // Deprecated, can be removed when CONNECT-2875 is merged
        jsonParams.flow_elements = this.workflowElementsData;
      }

      const result = testWorkflow
        ? ProcessManager.test(this.process.id, jsonParams)
        : ProcessManager.run(this.process.id, jsonParams);

      result
        .then(iteration => {
          this.$toast
            .fire({
              icon: "success",
              title: this.$t("processManager.processStarted"),
              showConfirmButton: true,
              confirmButtonColor: "#5b64ee",
              confirmButtonText: this.$t("processManager.openReporting")
            })
            .then(result => {
              if (!result.isConfirmed) {
                return;
              }
              if (!this.isInDesigner) {
                this.$router.push({
                  name: "projectWorkflowsJobDetails",
                  params: {
                    id: iteration.data.process_id,
                    jobId: iteration.data.id
                  }
                });
              }
              this.$emit("routeToDetails", {
                id: iteration.data.process_id,
                jobId: iteration.data.id
              });
            });
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
          this.$emit("close");
          this.workflowSubmitted = false;
        });
    },
    onChangeLoadingState(state) {
      if (state) {
        addEventToLoadingQueue({ key: "onChangeLoadingState" });
      } else {
        removeEventFromLoadingQueue({ key: "onChangeLoadingState" });
      }
    },
    testWorkflowJsonValidator(payload = null) {
      let error = null;

      try {
        if (payload.parameters.length) JSON.parse(payload.parameters);
      } catch (e) {
        error = e;
        this.testWorkflowInvalidJson = true;
      } finally {
        if (!error) {
          this.testWorkflowInvalidJson = false;
        }
      }
    }
  }
};
</script>
