<template>
  <div class="position-relative">
    <div>
      <Header
        :title="process.name"
        :subtitle="subtitle"
        :show-back-button="true"
        @back="back"
      >
        <template #afterItems>
          <button
            v-b-popover.hover.bottom="$t('general.showVersions')"
            class="btn btn-icon btn-outline-primary mr-1"
            @click="onShowVersions"
          >
            <i :class="$store.getters['config/icons'].version" />
          </button>
          <button
            v-if="saveButtonEnabled && !isPresetProject && isDevPresetVersion"
            v-b-popover.hover.bottom.html="
              useTooltipWithIcon($t('general.save'), keys.ctrl + 'S')
            "
            class="btn btn-icon btn-primary mr-1"
            :disabled="isSaving || isTesting"
            @click="validateAndSave"
          >
            <i
              :class="{
                'fal fa-floppy-disk': !isSaving && !isTesting,
                'spinner-border': isSaving || isTesting
              }"
            />
          </button>
          <button
            v-else-if="!isPresetProject && isDevPresetVersion"
            v-b-popover.hover.bottom="
              $t('workflowElements.saveButtonDisabledText')
            "
            class="btn btn-secondary mr-1"
            :disabled="isSaving || isTesting"
            @click="simulateSave"
          >
            <i
              :class="{
                'fal fa-floppy-disk': !isSaving && !isTesting,
                'spinner-border': isSaving || isTesting
              }"
            />
          </button>
          <button
            v-b-popover.hover.bottom.html="
              useTooltipWithIcon(
                $t('workflowElements.testWorkflow'),
                keys.shift + keys.ctrl + 'D'
              )
            "
            class="btn btn-primary btn-icon mr-1"
            :disabled="isTesting"
            @click="showTestWorkflowSidebar"
          >
            <i
              :class="{
                'fal fa-play': !isTesting,
                'spinner-border': isTesting
              }"
            />
          </button>
          <button
            v-if="saveButtonEnabled && !isPresetProject && isDevPresetVersion"
            v-b-popover.hover.bottom.html="
              useTooltipWithIcon(
                $t('workflowDesigner.saveAndTest'),
                keys.shift + keys.ctrl + 'S'
              )
            "
            class="btn btn-primary"
            :disabled="isSaving || isTesting"
            @click="saveAndTest"
          >
            <i
              :class="{
                'fal fa-floppy-disk-circle-arrow-right': !isTesting,
                'spinner-border': isTesting
              }"
            />
          </button>
          <button
            v-else-if="!isPresetProject && isDevPresetVersion"
            v-b-popover.hover.bottom="
              $t('workflowElements.saveButtonDisabledText')
            "
            class="btn btn-secondary"
            :disabled="isSaving || isTesting"
            @click="simulateSave"
          >
            <i
              :class="{
                'fal fa-floppy-disk-circle-arrow-right': !isTesting,
                'spinner-border': isTesting
              }"
            />
          </button>
        </template>
      </Header>
    </div>
    <div
      class="position-absolute left-0 d-flex w-100 mt-1 px-3 align-items-start library"
    >
      <div
        class="col-3 col-xl-2 position-absolute top-0 left-0"
        style="z-index: 10"
      >
        <div class="row flex-nowrap">
          <div class="col-auto pl-0">
            <div
              v-b-popover.hover.right="
                showLibrary
                  ? $t('workflowDesigner.componentLibrary.close')
                  : $t('workflowDesigner.componentLibrary.open')
              "
              class="bg-white rounded-sm p-2 cursor-pointer"
              style="line-height: 1; z-index: 5"
              @click="toggleLibrary"
            >
              <i
                class="fal icon-xl"
                :class="[showLibrary ? 'fa-book-open text-primary' : 'fa-book']"
              />
            </div>
            <button
              v-b-popover.hover.right.html="
                useTooltipWithIcon(
                  $t('workflowDesigner.openReporting'),
                  keys.shift + keys.ctrl + 'R'
                )
              "
              class="btn btn-icon btn-light bg-white rounded-sm p-2 cursor-pointer mt-3"
              @click.left="
                $emit('isModal', true);
                reportingSidebarVisible = true;
              "
              @click.middle="routeToLogs"
            >
              <i class="fal fa-search" />
            </button>
          </div>

          <div class="col pl-0">
            <Elements
              :show="showLibrary"
              @ready="readyToInit.library = true"
              @toggleCollapse="toggleLibrary"
            />
          </div>
        </div>
      </div>
      <div class="col-5 col-xl-6 py-0"></div>
      <div
        class="col-4 position-absolute top-0 right-0"
        style="z-index: 10"
      ></div>
    </div>
    <div id="designer-wrapper" class="w-100">
      <CanvasDesigner
        v-if="showCanvas"
        :library="allElements"
        :workflow="workflow"
        :process="process"
        @ready="$emit('busy', false)"
        @change="formHelperChanged"
      />
    </div>
    <ElementConfiguration
      :process="process"
      :output-values="outputValues"
      :all-workflow-elements="allElements"
      @change="formHelperChanged"
    />
    <!--  START: Test workflow sidebar  -->
    <StartSidebar
      :process="process"
      :workflow-show-sidebar="testWorkflowShowSidebar"
      :workflow-elements-data="testWorkflowElements"
      :is-in-designer="true"
      @close="testWorkflowShowSidebar = false"
      @routeToDetails="showReportingSideBar"
    />
    <!--  END: Test workflow sidebar  -->
    <!--  START: Reporting inside the WFD  -->
    <b-sidebar
      id="reportingSidebar"
      ref="reportingSidebar"
      v-model="reportingSidebarVisible"
      backdrop
      lazy
      no-header
      right
      no-close-on-route-change
      width="85%"
    >
      <Progressbar
        key="loadingSidebar"
        class="position-relative"
        progress-bar-key="loadingSidebar"
      />
      <div class="px-6 py-4">
        <Reporting
          ref="reporting"
          :is-modal="true"
          :process-data="process"
          @closeModal="reportingSidebarVisible = false"
        />
      </div>
    </b-sidebar>
    <!--  END: Reporting inside the WFD  -->
    <BackupModal
      v-model="showVersionsModal"
      :object="workflow"
      classname="Workflow"
    />
  </div>
</template>

<script>
import Elements from "@/components/Workflows/Designer/Canvas/Library.vue";
import CanvasDesigner from "@/components/Workflows/Designer/Canvas/Editor.vue";

import { bus } from "@/main";
import Workflows from "@/components/Workflows/Designer/workflows";

import { workflowElementToData } from "@/components/Workflows/Designer/Canvas/Components/editorHelpers";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";

import { formatDate } from "@/components/Tools/helperFunctions";
import Reporting from "@/components/Workflows/Reporting/Components/JobsTable.vue";
import { validate } from "@/components/Tools/FormHelper/validation";
import ElementConfiguration from "@/components/Workflows/Designer/Canvas/Configuration.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { useTooltipWithIcon } from "@/composables/useTooltipWithIcon";
import StartSidebar from "@/components/Tools/Workflows/StartSidebar.vue";
import { useWorkNote } from "@/composables/useWorkNote";
import ProcessManager from "@/components/Workflows/processManager";

const SIDEBAR_MODE_NONE = 0;
const SIDEBAR_MODE_LIBRARY = 1;

export default {
  components: {
    StartSidebar,
    Progressbar,
    BackupModal,
    Header,
    ElementConfiguration,
    Elements,
    CanvasDesigner,
    Reporting
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    workflow: {
      type: Object,
      default: () => {}
    },
    process: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      workflowChanged: false,
      reportingSidebarVisible: false,
      isTesting: false,
      isSaving: false,
      workflowElements: JSON.parse(JSON.stringify([...this.elements])),
      readyToInit: {
        library: false,
        allElements: false
      },
      selectedWidgetHash: "",
      showCanvas: false,
      fullScreen: false,
      nodes: [],
      outputValues: [],
      sidebarMode: SIDEBAR_MODE_NONE,
      testWorkflowShowSidebar: false,
      testWorkflowElements: [],
      form: [
        {
          type: "code",
          name: "parameters",
          lang: "json"
        }
      ],
      formCodeView: [
        {
          type: "code",
          name: "parameters",
          lang: "json",
          disabled: true
        }
      ],
      formConfig: {
        labelStacked: true
      },
      showVersionsModal: false,
      os: undefined,
      keys: {}
    };
  },
  computed: {
    ...mapGetters({
      allElements: GET_ALL_ELEMENTS,
      requestParams: "route/requestParams"
    }),
    ...mapGetters(["isPresetProject", "isDevPresetVersion"]),
    showLibrary: function () {
      return this.sidebarMode === SIDEBAR_MODE_LIBRARY;
    },
    saveButtonEnabled() {
      return (
        this.nodes.filter(x => x.attrs.data.flow_element_exists === false)
          .length <= 0
      );
    },
    subtitle: function () {
      if (!this.workflow) {
        return "";
      }

      let subtitleParts = [];
      if (this.workflow.updated_by_user?.full_name) {
        subtitleParts.push(this.workflow.updated_by_user?.full_name);
      }
      if (this.workflow.updated_at) {
        subtitleParts.push(formatDate(this.workflow.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    readyToInit: {
      deep: true,
      handler: function () {
        if (this.readyToInit.library && this.readyToInit.allElements)
          this.showCanvas = true;
      }
    },
    nodes: function () {
      this.getOutputValues();
    },
    allElements: function () {
      this.readyToInit.allElements = true;
    }
  },
  mounted() {
    this.subscribeBusEvents();
    if (this.allElements.length > 0) {
      this.readyToInit.allElements = true;
    }
    this.detectOS();
    window.addEventListener("keydown", this.shortCutsEvents);
  },
  destroyed() {
    this.unsubscribeBusEvents();
    window.removeEventListener("keydown", this.shortCutsEvents);
    this.reportingSidebarVisible = false;
  },
  methods: {
    useTooltipWithIcon,
    formatDate,
    showReportingSideBar(data) {
      this.reportingSidebarVisible = true;
      this.$nextTick().then(() => {
        this.$refs.reporting.showDetailsSidebarForJob(data.jobId);
      });
    },
    subscribeBusEvents() {
      bus.$on("nodesChanged", this.onNodesChanged);
      bus.$on("change-loading-state", this.onChangeLoadingState);
      bus.$on("outputValueChanged", this.addOutputValue);
    },
    formHelperChanged() {
      this.workflowChanged = true;
    },
    unsubscribeBusEvents() {
      bus.$off("nodesChanged", this.onNodesChanged);
      bus.$off("change-loading-state", this.onChangeLoadingState);
      bus.$off("outputValueChanged", this.addOutputValue);
    },
    getOutputValues() {
      for (const node of this.nodes) {
        if (node.attrs.data.output && node.attrs.data.output.length > 0) {
          node.attrs.data.output.forEach(output => {
            this.addOutputValue(output, node.attrs.data.hash);
          });
        }
      }
    },
    addOutputValue(item, nodeHash = null) {
      let hash = "";
      if (nodeHash) hash = nodeHash;
      if (item.hash) hash = item.hash;

      let val = this.outputValues.find(
        val => val.hash === hash && val.name === item.name
      );
      if (val) {
        val.value = item.value ?? item.name;
        val.data = item.data ? JSON.stringify(item.data) : null;
      } else {
        this.outputValues.push({
          hash: hash,
          name: item.name,
          value: item.value ?? item.name,
          data: item.data ? JSON.stringify(item.data) : null
        });
      }
      if (item.children) {
        item.children.forEach(child => {
          this.addOutputValue({
            value: (item.value ?? item.name) + "." + child.name,
            name: item.name + "." + child.name,
            hash: hash,
            data: child.data
          });
        });
      }
    },
    onNodesChanged(nodes) {
      this.nodes = nodes;
    },
    onChangeLoadingState(state) {
      if (state) {
        addEventToLoadingQueue({ key: "onChangeLoadingState" });
      } else {
        removeEventFromLoadingQueue({ key: "onChangeLoadingState" });
      }
    },
    back() {
      let unsavedChanges = this.checkUnsavedChanges();
      if (unsavedChanges) {
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("workflowDesigner.unsavedChangesTitle"),
            text: this.$t("workflowDesigner.unsavedChangesText"),
            showConfirmButton: true,
            confirmButtonText: this.$t("workflowDesigner.discardChanges"),
            showCancelButton: true,
            cancelButtonText: this.$t("general.cancel"),
            reverseButtons: true,
            confirmButtonColor: "#5b64ee"
          })
          .then(result => {
            if (!result.isConfirmed) {
              return;
            }
            this.$router.push({ name: "projectWorkflows" });
          });
      } else {
        this.$router.push({ name: "projectWorkflows" });
      }
    },
    onShowVersions() {
      this.showVersionsModal = true;
    },
    validateAndSave() {
      const errors = this.validate();
      if (errors.length > 0) {
        this.showValidationError(errors);
        return;
      }
      this.save();
    },
    showTestWorkflowSidebar() {
      this.testWorkflowElements = this.getWorkflowElementsData();
      this.testWorkflowShowSidebar = true;
    },
    async save(testAfterSave = false) {
      addEventToLoadingQueue({ key: "saveWorkflow" });
      this.isSaving = true;

      let workflowElements = this.getWorkflowElementsData();

      const data = {
        id: this.workflow.id,
        is_designer_process: true,
        configured_elements: workflowElements
      };

      const { addWorkNote } = useWorkNote();
      const { data: processData, success } = await addWorkNote(this.process);
      if (!success) {
        removeEventFromLoadingQueue({ key: "saveWorkflow" });
        this.isSaving = false;
        return;
      }

      Workflows.update(this.workflow.id, data)
        .then(response => {
          this.workflowChanged = false;
          this.workflowElements = [...response.data.configured_elements];
          removeEventFromLoadingQueue({
            key: "saveWorkflow",
            type: "success",
            prefix: "workflowDesigner",
            name: "workflowSaved"
          });
          if (processData.work_note) {
            ProcessManager.update(this.process.id, processData)
              .then(response => {
                this.$emit("updateProcess", response.data);
              })
              .catch(error => {
                this.$error(error);
              });
          }
          if (testAfterSave) {
            this.showTestWorkflowSidebar();
          }
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    saveAndTest() {
      const missingFields = this.validate();
      if (missingFields.length > 0) {
        this.showValidationError(missingFields);
        return;
      }
      this.save(true);
    },
    showValidationError(errors) {
      this.$toast.fire({
        icon: "error",
        title: this.$t("workflowDesigner.validationError"),
        html: "<ul><li>" + errors.join("</li><li>") + "</li></ul>"
      });
    },
    simulateSave() {
      this.validate();
    },
    validate() {
      let errors = [];
      this.nodes.forEach(node => {
        const data = node.attrs.data;
        if (data.active) {
          let config = {
            authentication: {},
            configuration: {},
            input: {},
            output: {},
            error: {}
          };
          let areas = [
            "authentication",
            "configuration",
            "input",
            "output",
            "error"
          ];
          areas.forEach(area => {
            let fields = [];
            data[area].forEach(field => {
              config[area][field.name] = field.value;
              fields.push(field);
            });
          });
          areas.forEach(area =>
            errors.push(...validate(config[area], data[area], true, "", config))
          );
        }
      });
      return errors;
    },
    toggleLibrary() {
      this.sidebarMode === SIDEBAR_MODE_LIBRARY
        ? (this.sidebarMode = SIDEBAR_MODE_NONE)
        : (this.sidebarMode = SIDEBAR_MODE_LIBRARY);

      if (this.sidebarMode === SIDEBAR_MODE_LIBRARY) {
        this.$nextTick().then(() => bus.$emit("library-visible"));
      }
    },
    checkUnsavedChanges() {
      let unsavedChanges = false;
      let originals = this.workflowElements;
      let elements = [];
      this.nodes
        .filter(n => n.attrs.data.save)
        .forEach(node => {
          let element = workflowElementToData(node);
          elements.push(JSON.parse(JSON.stringify(element)));
        });

      // Check if number of elements is the same
      if (originals.length !== elements.length) {
        return true;
      }

      if (this.workflowChanged) {
        return true;
      }

      originals.forEach(og => {
        let element = elements.find(e => e.hash === og.hash);
        // Check if element exists
        if (!element) {
          unsavedChanges = true;
          return;
        }
        // Check for next_hash, next_inner_hash
        if (og.next_hash !== element.next_hash) {
          unsavedChanges = true;
        }
      });
      return unsavedChanges;
    },
    routeToLogs() {
      this.$router.push({
        name: "projectWorkflowsReportingDetails",
        params: { id: this.process.id }
      });
    },
    getWorkflowElementsData() {
      let workflowElements = [];
      this.nodes.forEach(node => {
        if (
          node.attrs.data.output[0]?.data &&
          node.attrs.data.output[0]?.children
        ) {
          node.attrs.data.output[0].data = [];
          node.attrs.data.output[0].children = [];
        }
        if (node.attrs.data.save) {
          let workflowElement = workflowElementToData(node);
          workflowElements.push(workflowElement);
        }
      });
      return workflowElements;
    },
    shortCutsEvents(e) {
      if (!this.os) {
        this.detectOS();
      }
      const isMac = this.os === "macOS";
      const { keyCode, ctrlKey, metaKey, shiftKey } = e;

      if ((isMac && metaKey) || (!isMac && ctrlKey)) {
        if (shiftKey) {
          e.preventDefault();
          if (keyCode === 82) {
            // Open reporting (ctrl/cmd + shift + r)
            this.reportingSidebarVisible = !this.reportingSidebarVisible;
          } else if (keyCode === 83) {
            // Save and test workflow (ctrl/cmd + shift + s)
            this.saveAndTest();
          } else if (keyCode === 68) {
            // Test workflow (ctrl/cmd + shift + d)
            this.showTestWorkflowSidebar();
          }
        } else if (keyCode === 83) {
          // Save workflow (ctrl/cmd + s)
          e.preventDefault();
          this.validateAndSave();
        }
      }
    },
    detectOS() {
      this.os = navigator.userAgentData.platform;
      this.setOSBasedKeys();
    },
    setOSBasedKeys() {
      this.keys = {
        shift: "&#8679;",
        ctrl: this.os === "macOS" ? "&#8984;" : this.$t("general.controlKey")
      };
    }
  }
};
</script>

<style scoped lang="scss">
#designer-wrapper {
  height: calc(100vh - 100px);
}

.library {
  top: 50px;

  .rounded-sm {
    border-radius: 6px !important;
  }
}

:deep(.v-dialog) {
  height: 90vh;
}
</style>
