<template>
  <div>
    <Header
      :title="
        $t('workflowDesigner.reportingHeaderDetail', {
          workflow: process.name,
          date: jobStartedAt
        })
      "
      :show-back-button="true"
      :items="headerItems"
      @back="back"
      @requeueJob="requeueJob(job.id)"
    >
      <template #beforeItems>
        <button
          v-if="cancelStatus.includes(job.status)"
          v-b-popover.hover.top="$t('processManager.jobCancel')"
          class="btn btn-icon btn-sm btn-hover-light-primary mr-2"
          @click="cancelJob(job)"
        >
          <i class="fal fa-times-circle" />
        </button>
        <button
          v-if="!isModal"
          v-b-popover.top.hover="$t('workflowDesigner.openDesigner')"
          class="btn-icon btn-sm btn-hover-light-primary mr-2"
          @click="toDesigner"
          @click.middle="toDesigner"
        >
          <i class="fal fa-paint-brush" />
        </button>
      </template>
    </Header>
    <Details
      :is-modal="isModal"
      :process="process"
      :job="job"
      @close-modal="$emit('closeModal')"
    />
    <b-row class="pt-2">
      <b-col
        :md="childJobsCount > 0 ? 8 : 12"
        :class="childJobsCount > 0 ? 'pr-1' : ''"
      >
        <LogsTable :is-modal="isModal" :process="process" />
      </b-col>
      <!-- Show child jobs table -->
      <b-col v-show="childJobsCount > 0" md="4" class="pl-1">
        <ChildJobsTable
          :is-modal="isModal"
          :process="process"
          @iterations-loaded="onJobsLoaded"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Details from "@/components/Workflows/Reporting/Components/Detail.vue";
import LogsTable from "@/components/Workflows/Reporting/Components/LogsTable.vue";
import Processes from "@/components/Workflows/processManager";
import { formatDate } from "@/components/Tools/helperFunctions";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Iterations from "@/components/Workflows/processIteration";
import Swal from "sweetalert2";
import ChildJobsTable from "@/components/Workflows/Reporting/Components/ChildJobsTable.vue";

export default {
  name: "jobDetails",
  components: {
    ChildJobsTable,
    Header,
    Details,
    LogsTable
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    selectedJobDetailId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      headerItems: [
        {
          type: "button",
          tooltip: this.$t("processManager.requeue"),
          class: "btn-icon btn-sm btn-hover-light-primary",
          icon: "fal fa-reply",
          emit: "requeueJob"
        }
      ],
      process: {
        id: "",
        name: ""
      },
      job: {},
      childJobsCount: 0,
      cancelStatus: [
        "process.info",
        "process.pending",
        "process.working",
        "process.soft_error",
        "process.started",
        "process.waiting",
        "process.warning"
      ]
    };
  },
  computed: {
    jobStartedAt: function () {
      if (
        this.job === undefined ||
        this.job === null ||
        this.job.started_at === undefined ||
        this.job.started_at === null ||
        this.job.started_at === ""
      ) {
        return "";
      }
      return formatDate(this.job.started_at);
    }
  },
  watch: {
    "$route.params.jobId": {
      handler: function (jobId) {
        if (this.job.id && jobId !== this.job.id) {
          this.childJobsCount = 0;
          this.loadProcess();
          this.loadJob();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.loadProcess();
    this.loadJob();
  },
  methods: {
    formatDate,
    onJobsLoaded(jobsCount) {
      this.childJobsCount = jobsCount;
    },
    loadProcess() {
      if (this.$route.params.id === this.process.id) return;
      Processes.get(this.$route.params.id).then(response => {
        this.process = response.data;
      });
    },
    loadJob() {
      this.job = {};

      let eventParams = {
        key: "loadJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);

      let jobId = this.$route.params.jobId;
      Iterations.get(jobId)
        .then(response => {
          this.job = response.data;
          delete this.job.parameters._delay;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          removeEventFromLoadingQueue(eventParams);
        });
    },
    requeueJob(jobId) {
      let eventParams = {
        key: "requeueJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);
      Iterations.requeue(jobId)
        .then(() => {
          removeEventFromLoadingQueue({
            key: eventParams.key,
            progressBarKey: eventParams.progressBarKey,
            type: "success",
            prefix: "processManager",
            name: "jobRequeued"
          });
          this.$router.push({
            name: "projectWorkflowsReporting",
            params: { id: this.process.id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    cancelJob(iteration) {
      const statuses = ["process.error", "process.stopped", "process.aborted"];
      if (statuses.includes(iteration.status)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("queuedJobs.jobAbortFailed", {
            status: iteration.status
          })
        });
        return;
      }

      Swal.fire({
        title: this.$t("queuedJobs.deleteConfirm"),
        text: this.$t("queuedJobs.deleteConfirmText", {
          queue: this.process.name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#5b64ee",
        showCloseButton: true,
        cancelButtonText: this.$t("queuedJobs.noCancel"),
        confirmButtonText: this.$t("queuedJobs.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          let eventParams = {
            key: "cancelJob",
            progressBarKey: null
          };

          if (this.isModal) {
            eventParams.progressBarKey = "loadingSidebar";
          }

          addEventToLoadingQueue(eventParams);
          Iterations.abort(iteration.id)
            .then(response => {
              removeEventFromLoadingQueue({
                key: eventParams.key,
                progressBarKey: eventParams.progressBarKey,
                type: "success",
                prefix: "queuedJobs",
                name: "deleteSuccessText"
              });

              this.job.status = response.data.status;
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    },
    toDesigner() {
      this.$router.push({
        name: "projectWorkflowsEditor",
        params: { id: this.process.id }
      });
    },
    back() {
      if (!this.isModal) {
        this.$router.back();
        return;
      }
      this.$emit("close-modal");
    }
  }
};
</script>

<style scoped></style>
