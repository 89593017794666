<template>
  <b-modal
    id="WorkflowCommentModal"
    v-model="workflowCommentModalVisible"
    width="600px"
    bg-variant="white"
    right
    shadow
    backdrop-variant="dark"
    backdrop
    no-close-on-backdrop
    body-class="p-2"
    @hidden="resetDefault"
  >
    <div class="p-2">
      <b-row>
        <b-col sm="12" md="12" class="text-center">
          <h4>
            {{ $t("workflowComments.createTitle") }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <div class="form-group row align-items-center">
            <div class="col-md-12 col-lg-12 col-xl-12">
              <b-form-textarea
                v-model="note.comment"
                :rows="4"
                :placeholder="$t('workflowComments.inputPlaceholder')"
                :class="!$v.note.comment.required ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback v-if="!$v.note.comment.required">
                {{ $t("validation.required.workflowComment") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col sm="12" md="12">
          <b-button variant="btn btn-primary" block class="mt-2" @click="save">
            {{ $t("general.save") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import FlowComments from "@/components/FlowComments/flowComments";
import { required } from "vuelidate/lib/validators";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      note: {
        extra_data: {
          top: 200,
          left: 200,
          width: 250,
          height: 200,
          center: 200,
          fontSize: 20
        },
        comment: "",
        workflow_id: null
      },
      workflowCommentModalVisible: false
    };
  },
  validations: {
    note: {
      comment: { required }
    }
  },
  methods: {
    onEditorChange: debounce(function (value) {
      this.note.comment = value.html;
    }, 466),
    save() {
      if (this.$v.$invalid) {
        return;
      }
      if (this.type === "update") {
        FlowComments.update(this.note.id, this.note).then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("workflowComments.updatedText", {
              title: this.note.comment
            })
          });
          this.$emit("note-updated", this.note);
          this.resetDefault();
        });
        return;
      }
      FlowComments.store(this.note).then(response => {
        this.$toast.fire({
          icon: "success",
          title: this.$t("workflowComments.createdText", {
            title: this.note.note
          })
        });
        this.$emit("note-updated", response.data ?? this.note);
        this.resetDefault();
      });
    },
    resetDefault() {
      this.note = {
        comment: "",
        workflow_id: null,
        extra_data: {
          top: 200,
          left: 200,
          width: 250,
          height: 200,
          center: 200,
          fontSize: 20
        }
      };
      this.workflowCommentModalVisible = false;
    }
  }
};
</script>
