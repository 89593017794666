<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="onChange"
        @action="onAction"
      />
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { addAreaPrefix } from "@/components/Workflows/Designer/Canvas/Configuration/functions";
import { bus } from "@/main";
import _ from "lodash";
export default {
  components: { FormHelper },
  props: ["node", "configValues", "outputValues", "debugValues", "parameters"],
  data() {
    return {
      areas: ["authentication", "configuration", "input", "output", "error"],
      formConfig: {
        snippetPrefix: this.snippetPrefix(),
        labelStacked: true,
        enableVariables: true,
        customVariables: ["outputValues", "parameters"],
        distinctVariables: true,
        enableTypecast: true
      }
    };
  },
  computed: {
    values: {
      get() {
        let values = {};
        this.areas.forEach(area => (values[area] = {}));
        Object.keys(this.node.attrs.data).forEach(area => {
          if (!this.areas.includes(area)) {
            return;
          }
          this.node.attrs.data[area].forEach(field => {
            values[area][field.name] = field.value;
          });
        });
        return values;
      },
      set(values) {
        let config = _.cloneDeep(this.node.attrs.data.authentication);
        Object.keys(values.authentication).forEach(key => {
          let field = config.find(f => f.name === key);
          field.value = values.authentication[key];
        });
        this.node.attrs.data.authentication = config;
      }
    },
    form: function () {
      let form = this.node?.attrs.data.authentication ?? [];
      return addAreaPrefix(form, "authentication");
    }
  },
  mounted() {},
  methods: {
    snippetPrefix() {
      return (
        "workflowElements." +
        this.$store.getters.getElementByName(
          this.node.attrs.data.flow_element_name
        ).name
      );
    },
    onChange(payload) {
      this.checkOnChangeAction(payload);
      this.$emit("change");
    },
    checkOnChangeAction(payload) {
      let field = this.node.attrs.data.authentication.find(
        f => "authentication." + f.name === payload.name
      );
      if (!field.onChange) {
        return;
      }
      this.$nextTick().then(() => {
        bus.$emit(
          "fireAction",
          {
            name: field.onChange,
            label: field.onChange
          },
          false
        );
      });
    },
    onAction(actionName) {
      let field = this.form.find(field => field.action === actionName);
      bus.$emit("fireAction", field, false);
    }
  }
};
</script>
