<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { mapGetters } from "vuex";
import { GET_ALL_ELEMENTS } from "@/core/services/store/workflowDesigner.module";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import _ from "lodash";

export default {
  components: { FormHelper },
  props: ["node"],
  data() {
    return {
      formConfig: {
        snippetPrefix: "workflowDesigner",
        labelStacked: true
      },
      form: [
        {
          name: "label",
          type: "text",
          label: "label",
          onChange: this.emitTextChange
        },
        {
          name: "comment",
          type: "textarea",
          label: "comment"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      allElements: GET_ALL_ELEMENTS
    }),
    values: {
      get() {
        return Object.assign({}, this.node.attrs.data.planning);
      },
      set(values) {
        Object.assign(this.node.attrs.data.planning, values);
      }
    },
    systemWorkflowElementsIcons() {
      let returnData = [];
      this.allElements.forEach(element => {
        const assetData = element?.icon?.link;
        if (assetData) {
          returnData[element.id] = assetData;
        }
      });
      return returnData.filter(i => i !== null);
    },
    isReadonly() {
      return !this.node.attrs.data.save;
    },
    icon: function () {
      return this.node?.attrs.data.planning.icon;
    }
  },
  watch: {
    icon: function (icon, old) {
      if (this.node && icon !== old) {
        bus.$emit("iconChanged", this.node);
      }
    }
  },
  mounted() {},
  methods: {
    checkNodeConfig() {
      let planning = this.node.attrs.data?.planning ?? {};
      if (!Object.keys(planning).length) {
        this.node.attrs.data.planning = {
          label: "",
          description: "",
          comment: "",
          icon: ""
        };
      }
    },
    emitTextChange: _.debounce(function () {
      this.$nextTick().then(() => bus.$emit("textLabelChanged", this.node));
    }, 250)
  }
};
</script>

<style>
.editor {
  border: 0;
}
</style>
