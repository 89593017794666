<template>
  <div id="markdownBody" class="p-3"></div>
</template>

<script>
import { marked } from "marked";
export default {
  name: "Documentation",
  components: {},
  props: ["elementDocumentation"],
  mounted() {
    if (this.elementDocumentation) {
      const body = document.getElementById("markdownBody");
      body.innerHTML = marked.parse(this.elementDocumentation);
    }
  }
};
</script>
