import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";
let url = process.env.VUE_APP_API_ADMIN;

class Workflows extends BaseService {
  get(id) {
    return ApiService.get(url, "workflows/" + id);
  }
  getAll(params, filter = []) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    let filterParams = this.getParams(filter);
    return ApiService.get(url, "workflows" + options + filterParams);
  }
  update(id, data) {
    return ApiService.post(url + "/workflows/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/workflows/" + id);
  }
  getCommits(id, params) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    return ApiService.get(url, "workflows/" + id + "/commits" + options);
  }
}
export default new Workflows();
